import './PolygonEventRow.svelte.css';
/* src/PolygonEventRow.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "../_snowpack/pkg/svelte/internal.js";

import {
	picPercentageBelowThreshold,
	targetNumberBelowThreshold
} from "./TargetDataStore.js";

import { formatByMinute } from "./Utils.js";

function create_fragment(ctx) {
	let tr;
	let td0;
	let t0_value = formatByMinute(/*data*/ ctx[0].tomr) + "";
	let t0;
	let t1;
	let td1;
	let t2_value = /*data*/ ctx[0].count + "";
	let t2;
	let t3;
	let td2;
	let t4;
	let t5;

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			t4 = text(/*percentage*/ ctx[1]);
			t5 = text("%");
			attr(td0, "class", "date-cell svelte-17x7ctf");
			attr(td1, "class", "svelte-17x7ctf");
			attr(td2, "class", "svelte-17x7ctf");
			toggle_class(td2, "caution", /*percentage*/ ctx[1] >= /*$picPercentageBelowThreshold*/ ctx[2] && /*data*/ ctx[0].events > /*$targetNumberBelowThreshold*/ ctx[3]);
			attr(tr, "class", "svelte-17x7ctf");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, t4);
			append(td2, t5);
		},
		p(ctx, [dirty]) {
			if (dirty & /*data*/ 1 && t0_value !== (t0_value = formatByMinute(/*data*/ ctx[0].tomr) + "")) set_data(t0, t0_value);
			if (dirty & /*data*/ 1 && t2_value !== (t2_value = /*data*/ ctx[0].count + "")) set_data(t2, t2_value);
			if (dirty & /*percentage*/ 2) set_data(t4, /*percentage*/ ctx[1]);

			if (dirty & /*percentage, $picPercentageBelowThreshold, data, $targetNumberBelowThreshold*/ 15) {
				toggle_class(td2, "caution", /*percentage*/ ctx[1] >= /*$picPercentageBelowThreshold*/ ctx[2] && /*data*/ ctx[0].events > /*$targetNumberBelowThreshold*/ ctx[3]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let percentage;
	let $picPercentageBelowThreshold;
	let $targetNumberBelowThreshold;
	component_subscribe($$self, picPercentageBelowThreshold, $$value => $$invalidate(2, $picPercentageBelowThreshold = $$value));
	component_subscribe($$self, targetNumberBelowThreshold, $$value => $$invalidate(3, $targetNumberBelowThreshold = $$value));
	let { data = {} } = $$props;

	$$self.$$set = $$props => {
		if ("data" in $$props) $$invalidate(0, data = $$props.data);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*data*/ 1) {
			$: $$invalidate(1, percentage = (data.events / data.count * 100).toFixed(0));
		}
	};

	return [data, percentage, $picPercentageBelowThreshold, $targetNumberBelowThreshold];
}

class PolygonEventRow extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { data: 0 });
	}
}

export default PolygonEventRow;