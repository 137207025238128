import './PolygonTable.svelte.css';
/* src/PolygonTable.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_style,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { flights } from "./TargetDataStore.js";
import PolygonEventRow from "./PolygonEventRow.svelte.js";
import PolygonEventHistogram from "./PolygonEventHistogram.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

// (80:6) {#each counts as count}
function create_each_block(ctx) {
	let polygoneventrow;
	let current;
	polygoneventrow = new PolygonEventRow({ props: { data: /*count*/ ctx[3] } });

	return {
		c() {
			create_component(polygoneventrow.$$.fragment);
		},
		m(target, anchor) {
			mount_component(polygoneventrow, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const polygoneventrow_changes = {};
			if (dirty & /*counts*/ 1) polygoneventrow_changes.data = /*count*/ ctx[3];
			polygoneventrow.$set(polygoneventrow_changes);
		},
		i(local) {
			if (current) return;
			transition_in(polygoneventrow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(polygoneventrow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(polygoneventrow, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let table;
	let tr;
	let t5;
	let t6;
	let div0;
	let polygoneventhistogram;
	let current;
	let each_value = /*counts*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	polygoneventhistogram = new PolygonEventHistogram({ props: { countData: /*counts*/ ctx[0] } });

	return {
		c() {
			div1 = element("div");
			table = element("table");
			tr = element("tr");

			tr.innerHTML = `<th class="svelte-mhk39">Datetime (UTC)</th> 
         <th class="svelte-mhk39">Aircraft Count</th> 
         <th class="low-pic-percent svelte-mhk39">Aircraft with Low PIC</th>`;

			t5 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t6 = space();
			div0 = element("div");
			create_component(polygoneventhistogram.$$.fragment);
			set_style(tr, "text-align", "left");
			attr(table, "class", "svelte-mhk39");
			attr(div1, "class", "main-grid svelte-mhk39");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, table);
			append(table, tr);
			append(table, t5);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(table, null);
			}

			append(div1, t6);
			append(div1, div0);
			mount_component(polygoneventhistogram, div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*counts*/ 1) {
				each_value = /*counts*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(table, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			const polygoneventhistogram_changes = {};
			if (dirty & /*counts*/ 1) polygoneventhistogram_changes.countData = /*counts*/ ctx[0];
			polygoneventhistogram.$set(polygoneventhistogram_changes);
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(polygoneventhistogram.$$.fragment, local);
			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(polygoneventhistogram.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);
			destroy_component(polygoneventhistogram);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $flights;
	component_subscribe($$self, flights, $$value => $$invalidate(1, $flights = $$value));

	const groupByTomr = tomr => {
		let d = new Date(tomr);
		d.setSeconds(0);
		d.setMilliseconds(0);
		return d;
	};

	let counts = [];

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$flights*/ 2) {
			$: if ($flights.length > 0) {
				let thusFar = $flights.flatMap(flight => {
					let groupedHistory = flight.history.groupBy("tomr", groupByTomr);
					let groupedEventHistory = flight.events.groupBy("tomr", groupByTomr);

					let resultCounts = Object.keys(groupedHistory).map(key => {
						return { tomr: key, count: 1, event: 0 };
					});

					let resultEventCounts = Object.keys(groupedEventHistory).map(key => {
						let result = groupedEventHistory[key].some(data => data.eventType.toLowerCase() === "pic")
						? 1
						: 0;

						return { tomr: key, count: 0, event: result };
					});

					let result = resultCounts.reduce(
						(acc, curr) => {
							if (!acc.hasOwnProperty(curr.tomr)) {
								acc[curr.tomr] = [0, 0];
							}

							acc[curr.tomr][0] += curr.count;
							acc[curr.tomr][1] += curr.event;
							return acc;
						},
						{}
					);

					return resultEventCounts.reduce(
						(acc, curr) => {
							if (!acc.hasOwnProperty(curr.tomr)) {
								acc[curr.tomr] = [0, 0];
							}

							acc[curr.tomr][0] += curr.count;
							acc[curr.tomr][1] += curr.event;
							return acc;
						},
						result
					);
				});

				thusFar = thusFar.reduce(
					(acc, arr) => {
						Object.keys(arr).forEach(key => {
							let curr = arr[key];

							if (!acc.hasOwnProperty(key)) {
								acc[key] = [0, 0];
							}

							acc[key][0] += curr[0];
							acc[key][1] += curr[1];
						});

						return acc;
					},
					{}
				);

				$$invalidate(0, counts = Object.keys(thusFar).map(key => {
					return {
						tomr: new Date(key),
						count: thusFar[key][0],
						events: thusFar[key][1]
					};
				}).sort((a, b) => a.tomr > b.tomr ? 1 : -1));
			} else {
				$$invalidate(0, counts = []);
			}
		}
	};

	return [counts, $flights];
}

class PolygonTable extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default PolygonTable;