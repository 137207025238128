import './EventRow.svelte.css';
/* src/EventRow.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "../_snowpack/pkg/svelte/internal.js";

import { formatDate } from "./Utils.js";

function create_fragment(ctx) {
	let tr;
	let td0;
	let t0_value = formatDate(/*data*/ ctx[0].tomr) + "";
	let t0;
	let t1;
	let td1;
	let t2_value = (/*data*/ ctx[0].lat?.toFixed(4) ?? "---") + "";
	let t2;
	let t3;
	let td2;
	let t4_value = (/*data*/ ctx[0].lon?.toFixed(4) ?? "---") + "";
	let t4;
	let t5;
	let td3;
	let t6_value = (/*data*/ ctx[0].alt ?? "---") + "";
	let t6;
	let t7;
	let td4;
	let t8_value = /*data*/ ctx[0].pic + "";
	let t8;
	let t9;
	let td5;
	let t10_value = /*data*/ ctx[0].ipc + "";
	let t10;
	let t11;
	let td6;
	let t12_value = (/*data*/ ctx[0].groundSpeed?.toFixed(2) ?? "---") + "";
	let t12;
	let t13;
	let td7;
	let t14;

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			t4 = text(t4_value);
			t5 = space();
			td3 = element("td");
			t6 = text(t6_value);
			t7 = space();
			td4 = element("td");
			t8 = text(t8_value);
			t9 = space();
			td5 = element("td");
			t10 = text(t10_value);
			t11 = space();
			td6 = element("td");
			t12 = text(t12_value);
			t13 = space();
			td7 = element("td");
			t14 = text(/*notes*/ ctx[1]);
			attr(td0, "class", "date-cell svelte-17x7ctf");
			attr(td1, "class", "svelte-17x7ctf");
			attr(td2, "class", "svelte-17x7ctf");
			attr(td3, "class", "svelte-17x7ctf");
			attr(td4, "class", "svelte-17x7ctf");
			toggle_class(td4, "caution", /*data*/ ctx[0].pic < 7);
			attr(td5, "class", "svelte-17x7ctf");
			toggle_class(td5, "warning", /*data*/ ctx[0].ipc === 1);
			attr(td6, "class", "svelte-17x7ctf");
			attr(td7, "class", "notes-cell svelte-17x7ctf");
			attr(tr, "class", "svelte-17x7ctf");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, t4);
			append(tr, t5);
			append(tr, td3);
			append(td3, t6);
			append(tr, t7);
			append(tr, td4);
			append(td4, t8);
			append(tr, t9);
			append(tr, td5);
			append(td5, t10);
			append(tr, t11);
			append(tr, td6);
			append(td6, t12);
			append(tr, t13);
			append(tr, td7);
			append(td7, t14);
		},
		p(ctx, [dirty]) {
			if (dirty & /*data*/ 1 && t0_value !== (t0_value = formatDate(/*data*/ ctx[0].tomr) + "")) set_data(t0, t0_value);
			if (dirty & /*data*/ 1 && t2_value !== (t2_value = (/*data*/ ctx[0].lat?.toFixed(4) ?? "---") + "")) set_data(t2, t2_value);
			if (dirty & /*data*/ 1 && t4_value !== (t4_value = (/*data*/ ctx[0].lon?.toFixed(4) ?? "---") + "")) set_data(t4, t4_value);
			if (dirty & /*data*/ 1 && t6_value !== (t6_value = (/*data*/ ctx[0].alt ?? "---") + "")) set_data(t6, t6_value);
			if (dirty & /*data*/ 1 && t8_value !== (t8_value = /*data*/ ctx[0].pic + "")) set_data(t8, t8_value);

			if (dirty & /*data*/ 1) {
				toggle_class(td4, "caution", /*data*/ ctx[0].pic < 7);
			}

			if (dirty & /*data*/ 1 && t10_value !== (t10_value = /*data*/ ctx[0].ipc + "")) set_data(t10, t10_value);

			if (dirty & /*data*/ 1) {
				toggle_class(td5, "warning", /*data*/ ctx[0].ipc === 1);
			}

			if (dirty & /*data*/ 1 && t12_value !== (t12_value = (/*data*/ ctx[0].groundSpeed?.toFixed(2) ?? "---") + "")) set_data(t12, t12_value);
			if (dirty & /*notes*/ 2) set_data(t14, /*notes*/ ctx[1]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { data = {} } = $$props;
	let { notes = "" } = $$props;

	$$self.$$set = $$props => {
		if ("data" in $$props) $$invalidate(0, data = $$props.data);
		if ("notes" in $$props) $$invalidate(1, notes = $$props.notes);
	};

	return [data, notes];
}

class EventRow extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { data: 0, notes: 1 });
	}
}

export default EventRow;