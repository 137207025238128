import { m as maxSquaredDelta, a as assignClosestPoint, b as forEach, i as intersectsLineString, c as arrayMaxSquaredDelta, d as assignClosestArrayPoint, e as intersectsLineStringArray, g as multiArrayMaxSquaredDelta, h as assignClosestMultiArrayPoint, l as linearRingssContainsXY, j as linearRingss$1, o as orientLinearRingsArray, k as getInteriorPointsOfMultiArray, n as linearRingssAreOriented, P as Polygon, p as intersectsLinearRingMultiArray, F as Feature } from '../common/Polygon-9d13338e.js';
import { p as binarySearch, m as extend, b as assign, a as abstract, u as unlistenByKey, l as listen, E as EventType } from '../common/asserts-2fd060a4.js';
import { a as GeometryLayout, G as GeometryType, S as SimpleGeometry, b as Geometry } from '../common/SimpleGeometry-a3060d31.js';
import { m as closestSquaredDistanceXY, x as containsXY, v as createOrUpdateFromFlatCoordinates, p as createEmpty, f as createOrUpdateEmpty, y as extend$1, d as getCenter } from '../common/extent-0b9373cc.js';
import { d as deflateCoordinates, a as deflateCoordinatesArray, P as Point, b as deflateMultiCoordinatesArray } from '../common/Point-7ef0921b.js';
import { i as inflateCoordinates, d as douglasPeucker, a as inflateCoordinatesArray, b as douglasPeuckerArray, c as inflateMultiCoordinatesArray, e as quantizeMultiArray } from '../common/inflate-4edbbe7f.js';
import { l as lerp, s as squaredDistance } from '../common/math-b0fe2752.js';
import { l as lineStringLength } from '../common/length-2aab4c18.js';
import { d as get, U as Units, h as equivalent } from '../common/proj-bb01a6d4.js';
import { F as FormatType } from '../common/FormatType-a7472020.js';
import '../common/transform-df19ac96.js';

/**
 * @module ol/geom/flat/interpolate
 */
/**
 * @param {Array<number>} flatCoordinates Flat coordinates.
 * @param {number} offset Offset.
 * @param {number} end End.
 * @param {number} stride Stride.
 * @param {number} fraction Fraction.
 * @param {Array<number>=} opt_dest Destination.
 * @param {number=} opt_dimension Destination dimension (default is `2`)
 * @return {Array<number>} Destination.
 */
function interpolatePoint(flatCoordinates, offset, end, stride, fraction, opt_dest, opt_dimension) {
    var o, t;
    var n = (end - offset) / stride;
    if (n === 1) {
        o = offset;
    }
    else if (n === 2) {
        o = offset;
        t = fraction;
    }
    else if (n !== 0) {
        var x1 = flatCoordinates[offset];
        var y1 = flatCoordinates[offset + 1];
        var length_1 = 0;
        var cumulativeLengths = [0];
        for (var i = offset + stride; i < end; i += stride) {
            var x2 = flatCoordinates[i];
            var y2 = flatCoordinates[i + 1];
            length_1 += Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
            cumulativeLengths.push(length_1);
            x1 = x2;
            y1 = y2;
        }
        var target = fraction * length_1;
        var index = binarySearch(cumulativeLengths, target);
        if (index < 0) {
            t =
                (target - cumulativeLengths[-index - 2]) /
                    (cumulativeLengths[-index - 1] - cumulativeLengths[-index - 2]);
            o = offset + (-index - 2) * stride;
        }
        else {
            o = offset + index * stride;
        }
    }
    var dimension = opt_dimension > 1 ? opt_dimension : 2;
    var dest = opt_dest ? opt_dest : new Array(dimension);
    for (var i = 0; i < dimension; ++i) {
        dest[i] =
            o === undefined
                ? NaN
                : t === undefined
                    ? flatCoordinates[o + i]
                    : lerp(flatCoordinates[o + i], flatCoordinates[o + stride + i], t);
    }
    return dest;
}
/**
 * @param {Array<number>} flatCoordinates Flat coordinates.
 * @param {number} offset Offset.
 * @param {number} end End.
 * @param {number} stride Stride.
 * @param {number} m M.
 * @param {boolean} extrapolate Extrapolate.
 * @return {import("../../coordinate.js").Coordinate} Coordinate.
 */
function lineStringCoordinateAtM(flatCoordinates, offset, end, stride, m, extrapolate) {
    if (end == offset) {
        return null;
    }
    var coordinate;
    if (m < flatCoordinates[offset + stride - 1]) {
        if (extrapolate) {
            coordinate = flatCoordinates.slice(offset, offset + stride);
            coordinate[stride - 1] = m;
            return coordinate;
        }
        else {
            return null;
        }
    }
    else if (flatCoordinates[end - 1] < m) {
        if (extrapolate) {
            coordinate = flatCoordinates.slice(end - stride, end);
            coordinate[stride - 1] = m;
            return coordinate;
        }
        else {
            return null;
        }
    }
    // FIXME use O(1) search
    if (m == flatCoordinates[offset + stride - 1]) {
        return flatCoordinates.slice(offset, offset + stride);
    }
    var lo = offset / stride;
    var hi = end / stride;
    while (lo < hi) {
        var mid = (lo + hi) >> 1;
        if (m < flatCoordinates[(mid + 1) * stride - 1]) {
            hi = mid;
        }
        else {
            lo = mid + 1;
        }
    }
    var m0 = flatCoordinates[lo * stride - 1];
    if (m == m0) {
        return flatCoordinates.slice((lo - 1) * stride, (lo - 1) * stride + stride);
    }
    var m1 = flatCoordinates[(lo + 1) * stride - 1];
    var t = (m - m0) / (m1 - m0);
    coordinate = [];
    for (var i = 0; i < stride - 1; ++i) {
        coordinate.push(lerp(flatCoordinates[(lo - 1) * stride + i], flatCoordinates[lo * stride + i], t));
    }
    coordinate.push(m);
    return coordinate;
}
/**
 * @param {Array<number>} flatCoordinates Flat coordinates.
 * @param {number} offset Offset.
 * @param {Array<number>} ends Ends.
 * @param {number} stride Stride.
 * @param {number} m M.
 * @param {boolean} extrapolate Extrapolate.
 * @param {boolean} interpolate Interpolate.
 * @return {import("../../coordinate.js").Coordinate} Coordinate.
 */
function lineStringsCoordinateAtM(flatCoordinates, offset, ends, stride, m, extrapolate, interpolate) {
    if (interpolate) {
        return lineStringCoordinateAtM(flatCoordinates, offset, ends[ends.length - 1], stride, m, extrapolate);
    }
    var coordinate;
    if (m < flatCoordinates[stride - 1]) {
        if (extrapolate) {
            coordinate = flatCoordinates.slice(0, stride);
            coordinate[stride - 1] = m;
            return coordinate;
        }
        else {
            return null;
        }
    }
    if (flatCoordinates[flatCoordinates.length - 1] < m) {
        if (extrapolate) {
            coordinate = flatCoordinates.slice(flatCoordinates.length - stride);
            coordinate[stride - 1] = m;
            return coordinate;
        }
        else {
            return null;
        }
    }
    for (var i = 0, ii = ends.length; i < ii; ++i) {
        var end = ends[i];
        if (offset == end) {
            continue;
        }
        if (m < flatCoordinates[offset + stride - 1]) {
            return null;
        }
        else if (m <= flatCoordinates[end - 1]) {
            return lineStringCoordinateAtM(flatCoordinates, offset, end, stride, m, false);
        }
        offset = end;
    }
    return null;
}

var __extends = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @classdesc
 * Linestring geometry.
 *
 * @api
 */
var LineString = /** @class */ (function (_super) {
    __extends(LineString, _super);
    /**
     * @param {Array<import("../coordinate.js").Coordinate>|Array<number>} coordinates Coordinates.
     *     For internal use, flat coordinates in combination with `opt_layout` are also accepted.
     * @param {import("./GeometryLayout.js").default=} opt_layout Layout.
     */
    function LineString(coordinates, opt_layout) {
        var _this = _super.call(this) || this;
        /**
         * @private
         * @type {import("../coordinate.js").Coordinate}
         */
        _this.flatMidpoint_ = null;
        /**
         * @private
         * @type {number}
         */
        _this.flatMidpointRevision_ = -1;
        /**
         * @private
         * @type {number}
         */
        _this.maxDelta_ = -1;
        /**
         * @private
         * @type {number}
         */
        _this.maxDeltaRevision_ = -1;
        if (opt_layout !== undefined && !Array.isArray(coordinates[0])) {
            _this.setFlatCoordinates(opt_layout, 
            /** @type {Array<number>} */ (coordinates));
        }
        else {
            _this.setCoordinates(
            /** @type {Array<import("../coordinate.js").Coordinate>} */ (coordinates), opt_layout);
        }
        return _this;
    }
    /**
     * Append the passed coordinate to the coordinates of the linestring.
     * @param {import("../coordinate.js").Coordinate} coordinate Coordinate.
     * @api
     */
    LineString.prototype.appendCoordinate = function (coordinate) {
        if (!this.flatCoordinates) {
            this.flatCoordinates = coordinate.slice();
        }
        else {
            extend(this.flatCoordinates, coordinate);
        }
        this.changed();
    };
    /**
     * Make a complete copy of the geometry.
     * @return {!LineString} Clone.
     * @api
     */
    LineString.prototype.clone = function () {
        var lineString = new LineString(this.flatCoordinates.slice(), this.layout);
        lineString.applyProperties(this);
        return lineString;
    };
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @param {import("../coordinate.js").Coordinate} closestPoint Closest point.
     * @param {number} minSquaredDistance Minimum squared distance.
     * @return {number} Minimum squared distance.
     */
    LineString.prototype.closestPointXY = function (x, y, closestPoint, minSquaredDistance) {
        if (minSquaredDistance < closestSquaredDistanceXY(this.getExtent(), x, y)) {
            return minSquaredDistance;
        }
        if (this.maxDeltaRevision_ != this.getRevision()) {
            this.maxDelta_ = Math.sqrt(maxSquaredDelta(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, 0));
            this.maxDeltaRevision_ = this.getRevision();
        }
        return assignClosestPoint(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, this.maxDelta_, false, x, y, closestPoint, minSquaredDistance);
    };
    /**
     * Iterate over each segment, calling the provided callback.
     * If the callback returns a truthy value the function returns that
     * value immediately. Otherwise the function returns `false`.
     *
     * @param {function(this: S, import("../coordinate.js").Coordinate, import("../coordinate.js").Coordinate): T} callback Function
     *     called for each segment. The function will receive two arguments, the start and end coordinates of the segment.
     * @return {T|boolean} Value.
     * @template T,S
     * @api
     */
    LineString.prototype.forEachSegment = function (callback) {
        return forEach(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, callback);
    };
    /**
     * Returns the coordinate at `m` using linear interpolation, or `null` if no
     * such coordinate exists.
     *
     * `opt_extrapolate` controls extrapolation beyond the range of Ms in the
     * MultiLineString. If `opt_extrapolate` is `true` then Ms less than the first
     * M will return the first coordinate and Ms greater than the last M will
     * return the last coordinate.
     *
     * @param {number} m M.
     * @param {boolean=} opt_extrapolate Extrapolate. Default is `false`.
     * @return {import("../coordinate.js").Coordinate} Coordinate.
     * @api
     */
    LineString.prototype.getCoordinateAtM = function (m, opt_extrapolate) {
        if (this.layout != GeometryLayout.XYM &&
            this.layout != GeometryLayout.XYZM) {
            return null;
        }
        var extrapolate = opt_extrapolate !== undefined ? opt_extrapolate : false;
        return lineStringCoordinateAtM(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, m, extrapolate);
    };
    /**
     * Return the coordinates of the linestring.
     * @return {Array<import("../coordinate.js").Coordinate>} Coordinates.
     * @api
     */
    LineString.prototype.getCoordinates = function () {
        return inflateCoordinates(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride);
    };
    /**
     * Return the coordinate at the provided fraction along the linestring.
     * The `fraction` is a number between 0 and 1, where 0 is the start of the
     * linestring and 1 is the end.
     * @param {number} fraction Fraction.
     * @param {import("../coordinate.js").Coordinate=} opt_dest Optional coordinate whose values will
     *     be modified. If not provided, a new coordinate will be returned.
     * @return {import("../coordinate.js").Coordinate} Coordinate of the interpolated point.
     * @api
     */
    LineString.prototype.getCoordinateAt = function (fraction, opt_dest) {
        return interpolatePoint(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, fraction, opt_dest, this.stride);
    };
    /**
     * Return the length of the linestring on projected plane.
     * @return {number} Length (on projected plane).
     * @api
     */
    LineString.prototype.getLength = function () {
        return lineStringLength(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride);
    };
    /**
     * @return {Array<number>} Flat midpoint.
     */
    LineString.prototype.getFlatMidpoint = function () {
        if (this.flatMidpointRevision_ != this.getRevision()) {
            this.flatMidpoint_ = this.getCoordinateAt(0.5, this.flatMidpoint_);
            this.flatMidpointRevision_ = this.getRevision();
        }
        return this.flatMidpoint_;
    };
    /**
     * @param {number} squaredTolerance Squared tolerance.
     * @return {LineString} Simplified LineString.
     * @protected
     */
    LineString.prototype.getSimplifiedGeometryInternal = function (squaredTolerance) {
        var simplifiedFlatCoordinates = [];
        simplifiedFlatCoordinates.length = douglasPeucker(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, squaredTolerance, simplifiedFlatCoordinates, 0);
        return new LineString(simplifiedFlatCoordinates, GeometryLayout.XY);
    };
    /**
     * Get the type of this geometry.
     * @return {import("./GeometryType.js").default} Geometry type.
     * @api
     */
    LineString.prototype.getType = function () {
        return GeometryType.LINE_STRING;
    };
    /**
     * Test if the geometry and the passed extent intersect.
     * @param {import("../extent.js").Extent} extent Extent.
     * @return {boolean} `true` if the geometry and the extent intersect.
     * @api
     */
    LineString.prototype.intersectsExtent = function (extent) {
        return intersectsLineString(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, extent);
    };
    /**
     * Set the coordinates of the linestring.
     * @param {!Array<import("../coordinate.js").Coordinate>} coordinates Coordinates.
     * @param {import("./GeometryLayout.js").default=} opt_layout Layout.
     * @api
     */
    LineString.prototype.setCoordinates = function (coordinates, opt_layout) {
        this.setLayout(opt_layout, coordinates, 1);
        if (!this.flatCoordinates) {
            this.flatCoordinates = [];
        }
        this.flatCoordinates.length = deflateCoordinates(this.flatCoordinates, 0, coordinates, this.stride);
        this.changed();
    };
    return LineString;
}(SimpleGeometry));

var __extends$1 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @classdesc
 * Multi-linestring geometry.
 *
 * @api
 */
var MultiLineString = /** @class */ (function (_super) {
    __extends$1(MultiLineString, _super);
    /**
     * @param {Array<Array<import("../coordinate.js").Coordinate>|LineString>|Array<number>} coordinates
     *     Coordinates or LineString geometries. (For internal use, flat coordinates in
     *     combination with `opt_layout` and `opt_ends` are also accepted.)
     * @param {import("./GeometryLayout.js").default=} opt_layout Layout.
     * @param {Array<number>=} opt_ends Flat coordinate ends for internal use.
     */
    function MultiLineString(coordinates, opt_layout, opt_ends) {
        var _this = _super.call(this) || this;
        /**
         * @type {Array<number>}
         * @private
         */
        _this.ends_ = [];
        /**
         * @private
         * @type {number}
         */
        _this.maxDelta_ = -1;
        /**
         * @private
         * @type {number}
         */
        _this.maxDeltaRevision_ = -1;
        if (Array.isArray(coordinates[0])) {
            _this.setCoordinates(
            /** @type {Array<Array<import("../coordinate.js").Coordinate>>} */ (coordinates), opt_layout);
        }
        else if (opt_layout !== undefined && opt_ends) {
            _this.setFlatCoordinates(opt_layout, 
            /** @type {Array<number>} */ (coordinates));
            _this.ends_ = opt_ends;
        }
        else {
            var layout = _this.getLayout();
            var lineStrings = /** @type {Array<LineString>} */ (coordinates);
            var flatCoordinates = [];
            var ends = [];
            for (var i = 0, ii = lineStrings.length; i < ii; ++i) {
                var lineString = lineStrings[i];
                if (i === 0) {
                    layout = lineString.getLayout();
                }
                extend(flatCoordinates, lineString.getFlatCoordinates());
                ends.push(flatCoordinates.length);
            }
            _this.setFlatCoordinates(layout, flatCoordinates);
            _this.ends_ = ends;
        }
        return _this;
    }
    /**
     * Append the passed linestring to the multilinestring.
     * @param {LineString} lineString LineString.
     * @api
     */
    MultiLineString.prototype.appendLineString = function (lineString) {
        if (!this.flatCoordinates) {
            this.flatCoordinates = lineString.getFlatCoordinates().slice();
        }
        else {
            extend(this.flatCoordinates, lineString.getFlatCoordinates().slice());
        }
        this.ends_.push(this.flatCoordinates.length);
        this.changed();
    };
    /**
     * Make a complete copy of the geometry.
     * @return {!MultiLineString} Clone.
     * @api
     */
    MultiLineString.prototype.clone = function () {
        var multiLineString = new MultiLineString(this.flatCoordinates.slice(), this.layout, this.ends_.slice());
        multiLineString.applyProperties(this);
        return multiLineString;
    };
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @param {import("../coordinate.js").Coordinate} closestPoint Closest point.
     * @param {number} minSquaredDistance Minimum squared distance.
     * @return {number} Minimum squared distance.
     */
    MultiLineString.prototype.closestPointXY = function (x, y, closestPoint, minSquaredDistance) {
        if (minSquaredDistance < closestSquaredDistanceXY(this.getExtent(), x, y)) {
            return minSquaredDistance;
        }
        if (this.maxDeltaRevision_ != this.getRevision()) {
            this.maxDelta_ = Math.sqrt(arrayMaxSquaredDelta(this.flatCoordinates, 0, this.ends_, this.stride, 0));
            this.maxDeltaRevision_ = this.getRevision();
        }
        return assignClosestArrayPoint(this.flatCoordinates, 0, this.ends_, this.stride, this.maxDelta_, false, x, y, closestPoint, minSquaredDistance);
    };
    /**
     * Returns the coordinate at `m` using linear interpolation, or `null` if no
     * such coordinate exists.
     *
     * `opt_extrapolate` controls extrapolation beyond the range of Ms in the
     * MultiLineString. If `opt_extrapolate` is `true` then Ms less than the first
     * M will return the first coordinate and Ms greater than the last M will
     * return the last coordinate.
     *
     * `opt_interpolate` controls interpolation between consecutive LineStrings
     * within the MultiLineString. If `opt_interpolate` is `true` the coordinates
     * will be linearly interpolated between the last coordinate of one LineString
     * and the first coordinate of the next LineString.  If `opt_interpolate` is
     * `false` then the function will return `null` for Ms falling between
     * LineStrings.
     *
     * @param {number} m M.
     * @param {boolean=} opt_extrapolate Extrapolate. Default is `false`.
     * @param {boolean=} opt_interpolate Interpolate. Default is `false`.
     * @return {import("../coordinate.js").Coordinate} Coordinate.
     * @api
     */
    MultiLineString.prototype.getCoordinateAtM = function (m, opt_extrapolate, opt_interpolate) {
        if ((this.layout != GeometryLayout.XYM &&
            this.layout != GeometryLayout.XYZM) ||
            this.flatCoordinates.length === 0) {
            return null;
        }
        var extrapolate = opt_extrapolate !== undefined ? opt_extrapolate : false;
        var interpolate = opt_interpolate !== undefined ? opt_interpolate : false;
        return lineStringsCoordinateAtM(this.flatCoordinates, 0, this.ends_, this.stride, m, extrapolate, interpolate);
    };
    /**
     * Return the coordinates of the multilinestring.
     * @return {Array<Array<import("../coordinate.js").Coordinate>>} Coordinates.
     * @api
     */
    MultiLineString.prototype.getCoordinates = function () {
        return inflateCoordinatesArray(this.flatCoordinates, 0, this.ends_, this.stride);
    };
    /**
     * @return {Array<number>} Ends.
     */
    MultiLineString.prototype.getEnds = function () {
        return this.ends_;
    };
    /**
     * Return the linestring at the specified index.
     * @param {number} index Index.
     * @return {LineString} LineString.
     * @api
     */
    MultiLineString.prototype.getLineString = function (index) {
        if (index < 0 || this.ends_.length <= index) {
            return null;
        }
        return new LineString(this.flatCoordinates.slice(index === 0 ? 0 : this.ends_[index - 1], this.ends_[index]), this.layout);
    };
    /**
     * Return the linestrings of this multilinestring.
     * @return {Array<LineString>} LineStrings.
     * @api
     */
    MultiLineString.prototype.getLineStrings = function () {
        var flatCoordinates = this.flatCoordinates;
        var ends = this.ends_;
        var layout = this.layout;
        /** @type {Array<LineString>} */
        var lineStrings = [];
        var offset = 0;
        for (var i = 0, ii = ends.length; i < ii; ++i) {
            var end = ends[i];
            var lineString = new LineString(flatCoordinates.slice(offset, end), layout);
            lineStrings.push(lineString);
            offset = end;
        }
        return lineStrings;
    };
    /**
     * @return {Array<number>} Flat midpoints.
     */
    MultiLineString.prototype.getFlatMidpoints = function () {
        var midpoints = [];
        var flatCoordinates = this.flatCoordinates;
        var offset = 0;
        var ends = this.ends_;
        var stride = this.stride;
        for (var i = 0, ii = ends.length; i < ii; ++i) {
            var end = ends[i];
            var midpoint = interpolatePoint(flatCoordinates, offset, end, stride, 0.5);
            extend(midpoints, midpoint);
            offset = end;
        }
        return midpoints;
    };
    /**
     * @param {number} squaredTolerance Squared tolerance.
     * @return {MultiLineString} Simplified MultiLineString.
     * @protected
     */
    MultiLineString.prototype.getSimplifiedGeometryInternal = function (squaredTolerance) {
        var simplifiedFlatCoordinates = [];
        var simplifiedEnds = [];
        simplifiedFlatCoordinates.length = douglasPeuckerArray(this.flatCoordinates, 0, this.ends_, this.stride, squaredTolerance, simplifiedFlatCoordinates, 0, simplifiedEnds);
        return new MultiLineString(simplifiedFlatCoordinates, GeometryLayout.XY, simplifiedEnds);
    };
    /**
     * Get the type of this geometry.
     * @return {import("./GeometryType.js").default} Geometry type.
     * @api
     */
    MultiLineString.prototype.getType = function () {
        return GeometryType.MULTI_LINE_STRING;
    };
    /**
     * Test if the geometry and the passed extent intersect.
     * @param {import("../extent.js").Extent} extent Extent.
     * @return {boolean} `true` if the geometry and the extent intersect.
     * @api
     */
    MultiLineString.prototype.intersectsExtent = function (extent) {
        return intersectsLineStringArray(this.flatCoordinates, 0, this.ends_, this.stride, extent);
    };
    /**
     * Set the coordinates of the multilinestring.
     * @param {!Array<Array<import("../coordinate.js").Coordinate>>} coordinates Coordinates.
     * @param {GeometryLayout=} opt_layout Layout.
     * @api
     */
    MultiLineString.prototype.setCoordinates = function (coordinates, opt_layout) {
        this.setLayout(opt_layout, coordinates, 2);
        if (!this.flatCoordinates) {
            this.flatCoordinates = [];
        }
        var ends = deflateCoordinatesArray(this.flatCoordinates, 0, coordinates, this.stride, this.ends_);
        this.flatCoordinates.length = ends.length === 0 ? 0 : ends[ends.length - 1];
        this.changed();
    };
    return MultiLineString;
}(SimpleGeometry));

var __extends$2 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @classdesc
 * Multi-point geometry.
 *
 * @api
 */
var MultiPoint = /** @class */ (function (_super) {
    __extends$2(MultiPoint, _super);
    /**
     * @param {Array<import("../coordinate.js").Coordinate>|Array<number>} coordinates Coordinates.
     *     For internal use, flat coordinates in combination with `opt_layout` are also accepted.
     * @param {import("./GeometryLayout.js").default=} opt_layout Layout.
     */
    function MultiPoint(coordinates, opt_layout) {
        var _this = _super.call(this) || this;
        if (opt_layout && !Array.isArray(coordinates[0])) {
            _this.setFlatCoordinates(opt_layout, 
            /** @type {Array<number>} */ (coordinates));
        }
        else {
            _this.setCoordinates(
            /** @type {Array<import("../coordinate.js").Coordinate>} */ (coordinates), opt_layout);
        }
        return _this;
    }
    /**
     * Append the passed point to this multipoint.
     * @param {Point} point Point.
     * @api
     */
    MultiPoint.prototype.appendPoint = function (point) {
        if (!this.flatCoordinates) {
            this.flatCoordinates = point.getFlatCoordinates().slice();
        }
        else {
            extend(this.flatCoordinates, point.getFlatCoordinates());
        }
        this.changed();
    };
    /**
     * Make a complete copy of the geometry.
     * @return {!MultiPoint} Clone.
     * @api
     */
    MultiPoint.prototype.clone = function () {
        var multiPoint = new MultiPoint(this.flatCoordinates.slice(), this.layout);
        multiPoint.applyProperties(this);
        return multiPoint;
    };
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @param {import("../coordinate.js").Coordinate} closestPoint Closest point.
     * @param {number} minSquaredDistance Minimum squared distance.
     * @return {number} Minimum squared distance.
     */
    MultiPoint.prototype.closestPointXY = function (x, y, closestPoint, minSquaredDistance) {
        if (minSquaredDistance < closestSquaredDistanceXY(this.getExtent(), x, y)) {
            return minSquaredDistance;
        }
        var flatCoordinates = this.flatCoordinates;
        var stride = this.stride;
        for (var i = 0, ii = flatCoordinates.length; i < ii; i += stride) {
            var squaredDistance$1 = squaredDistance(x, y, flatCoordinates[i], flatCoordinates[i + 1]);
            if (squaredDistance$1 < minSquaredDistance) {
                minSquaredDistance = squaredDistance$1;
                for (var j = 0; j < stride; ++j) {
                    closestPoint[j] = flatCoordinates[i + j];
                }
                closestPoint.length = stride;
            }
        }
        return minSquaredDistance;
    };
    /**
     * Return the coordinates of the multipoint.
     * @return {Array<import("../coordinate.js").Coordinate>} Coordinates.
     * @api
     */
    MultiPoint.prototype.getCoordinates = function () {
        return inflateCoordinates(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride);
    };
    /**
     * Return the point at the specified index.
     * @param {number} index Index.
     * @return {Point} Point.
     * @api
     */
    MultiPoint.prototype.getPoint = function (index) {
        var n = !this.flatCoordinates
            ? 0
            : this.flatCoordinates.length / this.stride;
        if (index < 0 || n <= index) {
            return null;
        }
        return new Point(this.flatCoordinates.slice(index * this.stride, (index + 1) * this.stride), this.layout);
    };
    /**
     * Return the points of this multipoint.
     * @return {Array<Point>} Points.
     * @api
     */
    MultiPoint.prototype.getPoints = function () {
        var flatCoordinates = this.flatCoordinates;
        var layout = this.layout;
        var stride = this.stride;
        /** @type {Array<Point>} */
        var points = [];
        for (var i = 0, ii = flatCoordinates.length; i < ii; i += stride) {
            var point = new Point(flatCoordinates.slice(i, i + stride), layout);
            points.push(point);
        }
        return points;
    };
    /**
     * Get the type of this geometry.
     * @return {import("./GeometryType.js").default} Geometry type.
     * @api
     */
    MultiPoint.prototype.getType = function () {
        return GeometryType.MULTI_POINT;
    };
    /**
     * Test if the geometry and the passed extent intersect.
     * @param {import("../extent.js").Extent} extent Extent.
     * @return {boolean} `true` if the geometry and the extent intersect.
     * @api
     */
    MultiPoint.prototype.intersectsExtent = function (extent) {
        var flatCoordinates = this.flatCoordinates;
        var stride = this.stride;
        for (var i = 0, ii = flatCoordinates.length; i < ii; i += stride) {
            var x = flatCoordinates[i];
            var y = flatCoordinates[i + 1];
            if (containsXY(extent, x, y)) {
                return true;
            }
        }
        return false;
    };
    /**
     * Set the coordinates of the multipoint.
     * @param {!Array<import("../coordinate.js").Coordinate>} coordinates Coordinates.
     * @param {import("./GeometryLayout.js").default=} opt_layout Layout.
     * @api
     */
    MultiPoint.prototype.setCoordinates = function (coordinates, opt_layout) {
        this.setLayout(opt_layout, coordinates, 1);
        if (!this.flatCoordinates) {
            this.flatCoordinates = [];
        }
        this.flatCoordinates.length = deflateCoordinates(this.flatCoordinates, 0, coordinates, this.stride);
        this.changed();
    };
    return MultiPoint;
}(SimpleGeometry));

/**
 * @module ol/geom/flat/center
 */
/**
 * @param {Array<number>} flatCoordinates Flat coordinates.
 * @param {number} offset Offset.
 * @param {Array<Array<number>>} endss Endss.
 * @param {number} stride Stride.
 * @return {Array<number>} Flat centers.
 */
function linearRingss(flatCoordinates, offset, endss, stride) {
    var flatCenters = [];
    var extent = createEmpty();
    for (var i = 0, ii = endss.length; i < ii; ++i) {
        var ends = endss[i];
        extent = createOrUpdateFromFlatCoordinates(flatCoordinates, offset, ends[0], stride);
        flatCenters.push((extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2);
        offset = ends[ends.length - 1];
    }
    return flatCenters;
}

var __extends$3 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @classdesc
 * Multi-polygon geometry.
 *
 * @api
 */
var MultiPolygon = /** @class */ (function (_super) {
    __extends$3(MultiPolygon, _super);
    /**
     * @param {Array<Array<Array<import("../coordinate.js").Coordinate>>|Polygon>|Array<number>} coordinates Coordinates.
     *     For internal use, flat coordinates in combination with `opt_layout` and `opt_endss` are also accepted.
     * @param {import("./GeometryLayout.js").default=} opt_layout Layout.
     * @param {Array<Array<number>>=} opt_endss Array of ends for internal use with flat coordinates.
     */
    function MultiPolygon(coordinates, opt_layout, opt_endss) {
        var _this = _super.call(this) || this;
        /**
         * @type {Array<Array<number>>}
         * @private
         */
        _this.endss_ = [];
        /**
         * @private
         * @type {number}
         */
        _this.flatInteriorPointsRevision_ = -1;
        /**
         * @private
         * @type {Array<number>}
         */
        _this.flatInteriorPoints_ = null;
        /**
         * @private
         * @type {number}
         */
        _this.maxDelta_ = -1;
        /**
         * @private
         * @type {number}
         */
        _this.maxDeltaRevision_ = -1;
        /**
         * @private
         * @type {number}
         */
        _this.orientedRevision_ = -1;
        /**
         * @private
         * @type {Array<number>}
         */
        _this.orientedFlatCoordinates_ = null;
        if (!opt_endss && !Array.isArray(coordinates[0])) {
            var layout = _this.getLayout();
            var polygons = /** @type {Array<Polygon>} */ (coordinates);
            var flatCoordinates = [];
            var endss = [];
            for (var i = 0, ii = polygons.length; i < ii; ++i) {
                var polygon = polygons[i];
                if (i === 0) {
                    layout = polygon.getLayout();
                }
                var offset = flatCoordinates.length;
                var ends = polygon.getEnds();
                for (var j = 0, jj = ends.length; j < jj; ++j) {
                    ends[j] += offset;
                }
                extend(flatCoordinates, polygon.getFlatCoordinates());
                endss.push(ends);
            }
            opt_layout = layout;
            coordinates = flatCoordinates;
            opt_endss = endss;
        }
        if (opt_layout !== undefined && opt_endss) {
            _this.setFlatCoordinates(opt_layout, 
            /** @type {Array<number>} */ (coordinates));
            _this.endss_ = opt_endss;
        }
        else {
            _this.setCoordinates(
            /** @type {Array<Array<Array<import("../coordinate.js").Coordinate>>>} */ (coordinates), opt_layout);
        }
        return _this;
    }
    /**
     * Append the passed polygon to this multipolygon.
     * @param {Polygon} polygon Polygon.
     * @api
     */
    MultiPolygon.prototype.appendPolygon = function (polygon) {
        /** @type {Array<number>} */
        var ends;
        if (!this.flatCoordinates) {
            this.flatCoordinates = polygon.getFlatCoordinates().slice();
            ends = polygon.getEnds().slice();
            this.endss_.push();
        }
        else {
            var offset = this.flatCoordinates.length;
            extend(this.flatCoordinates, polygon.getFlatCoordinates());
            ends = polygon.getEnds().slice();
            for (var i = 0, ii = ends.length; i < ii; ++i) {
                ends[i] += offset;
            }
        }
        this.endss_.push(ends);
        this.changed();
    };
    /**
     * Make a complete copy of the geometry.
     * @return {!MultiPolygon} Clone.
     * @api
     */
    MultiPolygon.prototype.clone = function () {
        var len = this.endss_.length;
        var newEndss = new Array(len);
        for (var i = 0; i < len; ++i) {
            newEndss[i] = this.endss_[i].slice();
        }
        var multiPolygon = new MultiPolygon(this.flatCoordinates.slice(), this.layout, newEndss);
        multiPolygon.applyProperties(this);
        return multiPolygon;
    };
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @param {import("../coordinate.js").Coordinate} closestPoint Closest point.
     * @param {number} minSquaredDistance Minimum squared distance.
     * @return {number} Minimum squared distance.
     */
    MultiPolygon.prototype.closestPointXY = function (x, y, closestPoint, minSquaredDistance) {
        if (minSquaredDistance < closestSquaredDistanceXY(this.getExtent(), x, y)) {
            return minSquaredDistance;
        }
        if (this.maxDeltaRevision_ != this.getRevision()) {
            this.maxDelta_ = Math.sqrt(multiArrayMaxSquaredDelta(this.flatCoordinates, 0, this.endss_, this.stride, 0));
            this.maxDeltaRevision_ = this.getRevision();
        }
        return assignClosestMultiArrayPoint(this.getOrientedFlatCoordinates(), 0, this.endss_, this.stride, this.maxDelta_, true, x, y, closestPoint, minSquaredDistance);
    };
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @return {boolean} Contains (x, y).
     */
    MultiPolygon.prototype.containsXY = function (x, y) {
        return linearRingssContainsXY(this.getOrientedFlatCoordinates(), 0, this.endss_, this.stride, x, y);
    };
    /**
     * Return the area of the multipolygon on projected plane.
     * @return {number} Area (on projected plane).
     * @api
     */
    MultiPolygon.prototype.getArea = function () {
        return linearRingss$1(this.getOrientedFlatCoordinates(), 0, this.endss_, this.stride);
    };
    /**
     * Get the coordinate array for this geometry.  This array has the structure
     * of a GeoJSON coordinate array for multi-polygons.
     *
     * @param {boolean=} opt_right Orient coordinates according to the right-hand
     *     rule (counter-clockwise for exterior and clockwise for interior rings).
     *     If `false`, coordinates will be oriented according to the left-hand rule
     *     (clockwise for exterior and counter-clockwise for interior rings).
     *     By default, coordinate orientation will depend on how the geometry was
     *     constructed.
     * @return {Array<Array<Array<import("../coordinate.js").Coordinate>>>} Coordinates.
     * @api
     */
    MultiPolygon.prototype.getCoordinates = function (opt_right) {
        var flatCoordinates;
        if (opt_right !== undefined) {
            flatCoordinates = this.getOrientedFlatCoordinates().slice();
            orientLinearRingsArray(flatCoordinates, 0, this.endss_, this.stride, opt_right);
        }
        else {
            flatCoordinates = this.flatCoordinates;
        }
        return inflateMultiCoordinatesArray(flatCoordinates, 0, this.endss_, this.stride);
    };
    /**
     * @return {Array<Array<number>>} Endss.
     */
    MultiPolygon.prototype.getEndss = function () {
        return this.endss_;
    };
    /**
     * @return {Array<number>} Flat interior points.
     */
    MultiPolygon.prototype.getFlatInteriorPoints = function () {
        if (this.flatInteriorPointsRevision_ != this.getRevision()) {
            var flatCenters = linearRingss(this.flatCoordinates, 0, this.endss_, this.stride);
            this.flatInteriorPoints_ = getInteriorPointsOfMultiArray(this.getOrientedFlatCoordinates(), 0, this.endss_, this.stride, flatCenters);
            this.flatInteriorPointsRevision_ = this.getRevision();
        }
        return this.flatInteriorPoints_;
    };
    /**
     * Return the interior points as {@link module:ol/geom/MultiPoint multipoint}.
     * @return {MultiPoint} Interior points as XYM coordinates, where M is
     * the length of the horizontal intersection that the point belongs to.
     * @api
     */
    MultiPolygon.prototype.getInteriorPoints = function () {
        return new MultiPoint(this.getFlatInteriorPoints().slice(), GeometryLayout.XYM);
    };
    /**
     * @return {Array<number>} Oriented flat coordinates.
     */
    MultiPolygon.prototype.getOrientedFlatCoordinates = function () {
        if (this.orientedRevision_ != this.getRevision()) {
            var flatCoordinates = this.flatCoordinates;
            if (linearRingssAreOriented(flatCoordinates, 0, this.endss_, this.stride)) {
                this.orientedFlatCoordinates_ = flatCoordinates;
            }
            else {
                this.orientedFlatCoordinates_ = flatCoordinates.slice();
                this.orientedFlatCoordinates_.length = orientLinearRingsArray(this.orientedFlatCoordinates_, 0, this.endss_, this.stride);
            }
            this.orientedRevision_ = this.getRevision();
        }
        return this.orientedFlatCoordinates_;
    };
    /**
     * @param {number} squaredTolerance Squared tolerance.
     * @return {MultiPolygon} Simplified MultiPolygon.
     * @protected
     */
    MultiPolygon.prototype.getSimplifiedGeometryInternal = function (squaredTolerance) {
        var simplifiedFlatCoordinates = [];
        var simplifiedEndss = [];
        simplifiedFlatCoordinates.length = quantizeMultiArray(this.flatCoordinates, 0, this.endss_, this.stride, Math.sqrt(squaredTolerance), simplifiedFlatCoordinates, 0, simplifiedEndss);
        return new MultiPolygon(simplifiedFlatCoordinates, GeometryLayout.XY, simplifiedEndss);
    };
    /**
     * Return the polygon at the specified index.
     * @param {number} index Index.
     * @return {Polygon} Polygon.
     * @api
     */
    MultiPolygon.prototype.getPolygon = function (index) {
        if (index < 0 || this.endss_.length <= index) {
            return null;
        }
        var offset;
        if (index === 0) {
            offset = 0;
        }
        else {
            var prevEnds = this.endss_[index - 1];
            offset = prevEnds[prevEnds.length - 1];
        }
        var ends = this.endss_[index].slice();
        var end = ends[ends.length - 1];
        if (offset !== 0) {
            for (var i = 0, ii = ends.length; i < ii; ++i) {
                ends[i] -= offset;
            }
        }
        return new Polygon(this.flatCoordinates.slice(offset, end), this.layout, ends);
    };
    /**
     * Return the polygons of this multipolygon.
     * @return {Array<Polygon>} Polygons.
     * @api
     */
    MultiPolygon.prototype.getPolygons = function () {
        var layout = this.layout;
        var flatCoordinates = this.flatCoordinates;
        var endss = this.endss_;
        var polygons = [];
        var offset = 0;
        for (var i = 0, ii = endss.length; i < ii; ++i) {
            var ends = endss[i].slice();
            var end = ends[ends.length - 1];
            if (offset !== 0) {
                for (var j = 0, jj = ends.length; j < jj; ++j) {
                    ends[j] -= offset;
                }
            }
            var polygon = new Polygon(flatCoordinates.slice(offset, end), layout, ends);
            polygons.push(polygon);
            offset = end;
        }
        return polygons;
    };
    /**
     * Get the type of this geometry.
     * @return {import("./GeometryType.js").default} Geometry type.
     * @api
     */
    MultiPolygon.prototype.getType = function () {
        return GeometryType.MULTI_POLYGON;
    };
    /**
     * Test if the geometry and the passed extent intersect.
     * @param {import("../extent.js").Extent} extent Extent.
     * @return {boolean} `true` if the geometry and the extent intersect.
     * @api
     */
    MultiPolygon.prototype.intersectsExtent = function (extent) {
        return intersectsLinearRingMultiArray(this.getOrientedFlatCoordinates(), 0, this.endss_, this.stride, extent);
    };
    /**
     * Set the coordinates of the multipolygon.
     * @param {!Array<Array<Array<import("../coordinate.js").Coordinate>>>} coordinates Coordinates.
     * @param {import("./GeometryLayout.js").default=} opt_layout Layout.
     * @api
     */
    MultiPolygon.prototype.setCoordinates = function (coordinates, opt_layout) {
        this.setLayout(opt_layout, coordinates, 3);
        if (!this.flatCoordinates) {
            this.flatCoordinates = [];
        }
        var endss = deflateMultiCoordinatesArray(this.flatCoordinates, 0, coordinates, this.stride, this.endss_);
        if (endss.length === 0) {
            this.flatCoordinates.length = 0;
        }
        else {
            var lastEnds = endss[endss.length - 1];
            this.flatCoordinates.length =
                lastEnds.length === 0 ? 0 : lastEnds[lastEnds.length - 1];
        }
        this.changed();
    };
    return MultiPolygon;
}(SimpleGeometry));

/**
 * @module ol/format/Feature
 */
/**
 * @typedef {Object} ReadOptions
 * @property {import("../proj.js").ProjectionLike} [dataProjection] Projection of the data we are reading.
 * If not provided, the projection will be derived from the data (where possible) or
 * the `dataProjection` of the format is assigned (where set). If the projection
 * can not be derived from the data and if no `dataProjection` is set for a format,
 * the features will not be reprojected.
 * @property {import("../extent.js").Extent} [extent] Tile extent in map units of the tile being read.
 * This is only required when reading data with tile pixels as geometry units. When configured,
 * a `dataProjection` with `TILE_PIXELS` as `units` and the tile's pixel extent as `extent` needs to be
 * provided.
 * @property {import("../proj.js").ProjectionLike} [featureProjection] Projection of the feature geometries
 * created by the format reader. If not provided, features will be returned in the
 * `dataProjection`.
 */
/**
 * @typedef {Object} WriteOptions
 * @property {import("../proj.js").ProjectionLike} [dataProjection] Projection of the data we are writing.
 * If not provided, the `dataProjection` of the format is assigned (where set).
 * If no `dataProjection` is set for a format, the features will be returned
 * in the `featureProjection`.
 * @property {import("../proj.js").ProjectionLike} [featureProjection] Projection of the feature geometries
 * that will be serialized by the format writer. If not provided, geometries are assumed
 * to be in the `dataProjection` if that is set; in other words, they are not transformed.
 * @property {boolean} [rightHanded] When writing geometries, follow the right-hand
 * rule for linear ring orientation.  This means that polygons will have counter-clockwise
 * exterior rings and clockwise interior rings.  By default, coordinates are serialized
 * as they are provided at construction.  If `true`, the right-hand rule will
 * be applied.  If `false`, the left-hand rule will be applied (clockwise for
 * exterior and counter-clockwise for interior rings).  Note that not all
 * formats support this.  The GeoJSON format does use this property when writing
 * geometries.
 * @property {number} [decimals] Maximum number of decimal places for coordinates.
 * Coordinates are stored internally as floats, but floating-point arithmetic can create
 * coordinates with a large number of decimal places, not generally wanted on output.
 * Set a number here to round coordinates. Can also be used to ensure that
 * coordinates read in can be written back out with the same number of decimals.
 * Default is no rounding.
 */
/**
 * @classdesc
 * Abstract base class; normally only used for creating subclasses and not
 * instantiated in apps.
 * Base class for feature formats.
 * {@link module:ol/format/Feature~FeatureFormat} subclasses provide the ability to decode and encode
 * {@link module:ol/Feature~Feature} objects from a variety of commonly used geospatial
 * file formats.  See the documentation for each format for more details.
 *
 * @abstract
 * @api
 */
var FeatureFormat = /** @class */ (function () {
    function FeatureFormat() {
        /**
         * @protected
         * @type {import("../proj/Projection.js").default}
         */
        this.dataProjection = null;
        /**
         * @protected
         * @type {import("../proj/Projection.js").default}
         */
        this.defaultFeatureProjection = null;
    }
    /**
     * Adds the data projection to the read options.
     * @param {Document|Element|Object|string} source Source.
     * @param {ReadOptions=} opt_options Options.
     * @return {ReadOptions|undefined} Options.
     * @protected
     */
    FeatureFormat.prototype.getReadOptions = function (source, opt_options) {
        var options;
        if (opt_options) {
            var dataProjection = opt_options.dataProjection
                ? get(opt_options.dataProjection)
                : this.readProjection(source);
            if (opt_options.extent &&
                dataProjection &&
                dataProjection.getUnits() === Units.TILE_PIXELS) {
                dataProjection = get(dataProjection);
                dataProjection.setWorldExtent(opt_options.extent);
            }
            options = {
                dataProjection: dataProjection,
                featureProjection: opt_options.featureProjection,
            };
        }
        return this.adaptOptions(options);
    };
    /**
     * Sets the `dataProjection` on the options, if no `dataProjection`
     * is set.
     * @param {WriteOptions|ReadOptions|undefined} options
     *     Options.
     * @protected
     * @return {WriteOptions|ReadOptions|undefined}
     *     Updated options.
     */
    FeatureFormat.prototype.adaptOptions = function (options) {
        return assign({
            dataProjection: this.dataProjection,
            featureProjection: this.defaultFeatureProjection,
        }, options);
    };
    /**
     * @abstract
     * @return {import("./FormatType.js").default} Format.
     */
    FeatureFormat.prototype.getType = function () {
        return abstract();
    };
    /**
     * Read a single feature from a source.
     *
     * @abstract
     * @param {Document|Element|Object|string} source Source.
     * @param {ReadOptions=} opt_options Read options.
     * @return {import("../Feature.js").FeatureLike} Feature.
     */
    FeatureFormat.prototype.readFeature = function (source, opt_options) {
        return abstract();
    };
    /**
     * Read all features from a source.
     *
     * @abstract
     * @param {Document|Element|ArrayBuffer|Object|string} source Source.
     * @param {ReadOptions=} opt_options Read options.
     * @return {Array<import("../Feature.js").FeatureLike>} Features.
     */
    FeatureFormat.prototype.readFeatures = function (source, opt_options) {
        return abstract();
    };
    /**
     * Read a single geometry from a source.
     *
     * @abstract
     * @param {Document|Element|Object|string} source Source.
     * @param {ReadOptions=} opt_options Read options.
     * @return {import("../geom/Geometry.js").default} Geometry.
     */
    FeatureFormat.prototype.readGeometry = function (source, opt_options) {
        return abstract();
    };
    /**
     * Read the projection from a source.
     *
     * @abstract
     * @param {Document|Element|Object|string} source Source.
     * @return {import("../proj/Projection.js").default} Projection.
     */
    FeatureFormat.prototype.readProjection = function (source) {
        return abstract();
    };
    /**
     * Encode a feature in this format.
     *
     * @abstract
     * @param {import("../Feature.js").default} feature Feature.
     * @param {WriteOptions=} opt_options Write options.
     * @return {string} Result.
     */
    FeatureFormat.prototype.writeFeature = function (feature, opt_options) {
        return abstract();
    };
    /**
     * Encode an array of features in this format.
     *
     * @abstract
     * @param {Array<import("../Feature.js").default>} features Features.
     * @param {WriteOptions=} opt_options Write options.
     * @return {string} Result.
     */
    FeatureFormat.prototype.writeFeatures = function (features, opt_options) {
        return abstract();
    };
    /**
     * Write a single geometry in this format.
     *
     * @abstract
     * @param {import("../geom/Geometry.js").default} geometry Geometry.
     * @param {WriteOptions=} opt_options Write options.
     * @return {string} Result.
     */
    FeatureFormat.prototype.writeGeometry = function (geometry, opt_options) {
        return abstract();
    };
    return FeatureFormat;
}());
/**
 * @param {import("../geom/Geometry.js").default} geometry Geometry.
 * @param {boolean} write Set to true for writing, false for reading.
 * @param {(WriteOptions|ReadOptions)=} opt_options Options.
 * @return {import("../geom/Geometry.js").default} Transformed geometry.
 */
function transformGeometryWithOptions(geometry, write, opt_options) {
    var featureProjection = opt_options
        ? get(opt_options.featureProjection)
        : null;
    var dataProjection = opt_options
        ? get(opt_options.dataProjection)
        : null;
    var transformed;
    if (featureProjection &&
        dataProjection &&
        !equivalent(featureProjection, dataProjection)) {
        transformed = (write ? geometry.clone() : geometry).transform(write ? featureProjection : dataProjection, write ? dataProjection : featureProjection);
    }
    else {
        transformed = geometry;
    }
    if (write &&
        opt_options &&
        /** @type {WriteOptions} */ (opt_options).decimals !== undefined) {
        var power_1 = Math.pow(10, 
        /** @type {WriteOptions} */ (opt_options).decimals);
        // if decimals option on write, round each coordinate appropriately
        /**
         * @param {Array<number>} coordinates Coordinates.
         * @return {Array<number>} Transformed coordinates.
         */
        var transform = function (coordinates) {
            for (var i = 0, ii = coordinates.length; i < ii; ++i) {
                coordinates[i] = Math.round(coordinates[i] * power_1) / power_1;
            }
            return coordinates;
        };
        if (transformed === geometry) {
            transformed = geometry.clone();
        }
        transformed.applyTransform(transform);
    }
    return transformed;
}

var __extends$4 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @classdesc
 * An array of {@link module:ol/geom/Geometry} objects.
 *
 * @api
 */
var GeometryCollection = /** @class */ (function (_super) {
    __extends$4(GeometryCollection, _super);
    /**
     * @param {Array<Geometry>=} opt_geometries Geometries.
     */
    function GeometryCollection(opt_geometries) {
        var _this = _super.call(this) || this;
        /**
         * @private
         * @type {Array<Geometry>}
         */
        _this.geometries_ = opt_geometries ? opt_geometries : null;
        /**
         * @type {Array<import("../events.js").EventsKey>}
         */
        _this.changeEventsKeys_ = [];
        _this.listenGeometriesChange_();
        return _this;
    }
    /**
     * @private
     */
    GeometryCollection.prototype.unlistenGeometriesChange_ = function () {
        this.changeEventsKeys_.forEach(unlistenByKey);
        this.changeEventsKeys_.length = 0;
    };
    /**
     * @private
     */
    GeometryCollection.prototype.listenGeometriesChange_ = function () {
        if (!this.geometries_) {
            return;
        }
        for (var i = 0, ii = this.geometries_.length; i < ii; ++i) {
            this.changeEventsKeys_.push(listen(this.geometries_[i], EventType.CHANGE, this.changed, this));
        }
    };
    /**
     * Make a complete copy of the geometry.
     * @return {!GeometryCollection} Clone.
     * @api
     */
    GeometryCollection.prototype.clone = function () {
        var geometryCollection = new GeometryCollection(null);
        geometryCollection.setGeometries(this.geometries_);
        geometryCollection.applyProperties(this);
        return geometryCollection;
    };
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @param {import("../coordinate.js").Coordinate} closestPoint Closest point.
     * @param {number} minSquaredDistance Minimum squared distance.
     * @return {number} Minimum squared distance.
     */
    GeometryCollection.prototype.closestPointXY = function (x, y, closestPoint, minSquaredDistance) {
        if (minSquaredDistance < closestSquaredDistanceXY(this.getExtent(), x, y)) {
            return minSquaredDistance;
        }
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            minSquaredDistance = geometries[i].closestPointXY(x, y, closestPoint, minSquaredDistance);
        }
        return minSquaredDistance;
    };
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @return {boolean} Contains (x, y).
     */
    GeometryCollection.prototype.containsXY = function (x, y) {
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            if (geometries[i].containsXY(x, y)) {
                return true;
            }
        }
        return false;
    };
    /**
     * @param {import("../extent.js").Extent} extent Extent.
     * @protected
     * @return {import("../extent.js").Extent} extent Extent.
     */
    GeometryCollection.prototype.computeExtent = function (extent) {
        createOrUpdateEmpty(extent);
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            extend$1(extent, geometries[i].getExtent());
        }
        return extent;
    };
    /**
     * Return the geometries that make up this geometry collection.
     * @return {Array<Geometry>} Geometries.
     * @api
     */
    GeometryCollection.prototype.getGeometries = function () {
        return cloneGeometries(this.geometries_);
    };
    /**
     * @return {Array<Geometry>} Geometries.
     */
    GeometryCollection.prototype.getGeometriesArray = function () {
        return this.geometries_;
    };
    /**
     * @return {Array<Geometry>} Geometries.
     */
    GeometryCollection.prototype.getGeometriesArrayRecursive = function () {
        /** @type {Array<Geometry>} */
        var geometriesArray = [];
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            if (geometries[i].getType() === this.getType()) {
                geometriesArray = geometriesArray.concat(
                /** @type {GeometryCollection} */ (geometries[i]).getGeometriesArrayRecursive());
            }
            else {
                geometriesArray.push(geometries[i]);
            }
        }
        return geometriesArray;
    };
    /**
     * Create a simplified version of this geometry using the Douglas Peucker algorithm.
     * @param {number} squaredTolerance Squared tolerance.
     * @return {GeometryCollection} Simplified GeometryCollection.
     */
    GeometryCollection.prototype.getSimplifiedGeometry = function (squaredTolerance) {
        if (this.simplifiedGeometryRevision !== this.getRevision()) {
            this.simplifiedGeometryMaxMinSquaredTolerance = 0;
            this.simplifiedGeometryRevision = this.getRevision();
        }
        if (squaredTolerance < 0 ||
            (this.simplifiedGeometryMaxMinSquaredTolerance !== 0 &&
                squaredTolerance < this.simplifiedGeometryMaxMinSquaredTolerance)) {
            return this;
        }
        var simplifiedGeometries = [];
        var geometries = this.geometries_;
        var simplified = false;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            var geometry = geometries[i];
            var simplifiedGeometry = geometry.getSimplifiedGeometry(squaredTolerance);
            simplifiedGeometries.push(simplifiedGeometry);
            if (simplifiedGeometry !== geometry) {
                simplified = true;
            }
        }
        if (simplified) {
            var simplifiedGeometryCollection = new GeometryCollection(null);
            simplifiedGeometryCollection.setGeometriesArray(simplifiedGeometries);
            return simplifiedGeometryCollection;
        }
        else {
            this.simplifiedGeometryMaxMinSquaredTolerance = squaredTolerance;
            return this;
        }
    };
    /**
     * Get the type of this geometry.
     * @return {import("./GeometryType.js").default} Geometry type.
     * @api
     */
    GeometryCollection.prototype.getType = function () {
        return GeometryType.GEOMETRY_COLLECTION;
    };
    /**
     * Test if the geometry and the passed extent intersect.
     * @param {import("../extent.js").Extent} extent Extent.
     * @return {boolean} `true` if the geometry and the extent intersect.
     * @api
     */
    GeometryCollection.prototype.intersectsExtent = function (extent) {
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            if (geometries[i].intersectsExtent(extent)) {
                return true;
            }
        }
        return false;
    };
    /**
     * @return {boolean} Is empty.
     */
    GeometryCollection.prototype.isEmpty = function () {
        return this.geometries_.length === 0;
    };
    /**
     * Rotate the geometry around a given coordinate. This modifies the geometry
     * coordinates in place.
     * @param {number} angle Rotation angle in radians.
     * @param {import("../coordinate.js").Coordinate} anchor The rotation center.
     * @api
     */
    GeometryCollection.prototype.rotate = function (angle, anchor) {
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            geometries[i].rotate(angle, anchor);
        }
        this.changed();
    };
    /**
     * Scale the geometry (with an optional origin).  This modifies the geometry
     * coordinates in place.
     * @abstract
     * @param {number} sx The scaling factor in the x-direction.
     * @param {number=} opt_sy The scaling factor in the y-direction (defaults to sx).
     * @param {import("../coordinate.js").Coordinate=} opt_anchor The scale origin (defaults to the center
     *     of the geometry extent).
     * @api
     */
    GeometryCollection.prototype.scale = function (sx, opt_sy, opt_anchor) {
        var anchor = opt_anchor;
        if (!anchor) {
            anchor = getCenter(this.getExtent());
        }
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            geometries[i].scale(sx, opt_sy, anchor);
        }
        this.changed();
    };
    /**
     * Set the geometries that make up this geometry collection.
     * @param {Array<Geometry>} geometries Geometries.
     * @api
     */
    GeometryCollection.prototype.setGeometries = function (geometries) {
        this.setGeometriesArray(cloneGeometries(geometries));
    };
    /**
     * @param {Array<Geometry>} geometries Geometries.
     */
    GeometryCollection.prototype.setGeometriesArray = function (geometries) {
        this.unlistenGeometriesChange_();
        this.geometries_ = geometries;
        this.listenGeometriesChange_();
        this.changed();
    };
    /**
     * Apply a transform function to the coordinates of the geometry.
     * The geometry is modified in place.
     * If you do not want the geometry modified in place, first `clone()` it and
     * then use this function on the clone.
     * @param {import("../proj.js").TransformFunction} transformFn Transform function.
     * Called with a flat array of geometry coordinates.
     * @api
     */
    GeometryCollection.prototype.applyTransform = function (transformFn) {
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            geometries[i].applyTransform(transformFn);
        }
        this.changed();
    };
    /**
     * Translate the geometry.  This modifies the geometry coordinates in place.  If
     * instead you want a new geometry, first `clone()` this geometry.
     * @param {number} deltaX Delta X.
     * @param {number} deltaY Delta Y.
     * @api
     */
    GeometryCollection.prototype.translate = function (deltaX, deltaY) {
        var geometries = this.geometries_;
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            geometries[i].translate(deltaX, deltaY);
        }
        this.changed();
    };
    /**
     * Clean up.
     */
    GeometryCollection.prototype.disposeInternal = function () {
        this.unlistenGeometriesChange_();
        _super.prototype.disposeInternal.call(this);
    };
    return GeometryCollection;
}(Geometry));
/**
 * @param {Array<Geometry>} geometries Geometries.
 * @return {Array<Geometry>} Cloned geometries.
 */
function cloneGeometries(geometries) {
    var clonedGeometries = [];
    for (var i = 0, ii = geometries.length; i < ii; ++i) {
        clonedGeometries.push(geometries[i].clone());
    }
    return clonedGeometries;
}

var __extends$5 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @classdesc
 * Abstract base class; normally only used for creating subclasses and not
 * instantiated in apps.
 * Base class for text feature formats.
 *
 * @abstract
 */
var TextFeature = /** @class */ (function (_super) {
    __extends$5(TextFeature, _super);
    function TextFeature() {
        return _super.call(this) || this;
    }
    /**
     * @return {import("./FormatType.js").default} Format.
     */
    TextFeature.prototype.getType = function () {
        return FormatType.TEXT;
    };
    /**
     * Read the feature from the source.
     *
     * @param {Document|Element|Object|string} source Source.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @return {import("../Feature.js").default} Feature.
     * @api
     */
    TextFeature.prototype.readFeature = function (source, opt_options) {
        return this.readFeatureFromText(getText(source), this.adaptOptions(opt_options));
    };
    /**
     * @abstract
     * @param {string} text Text.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @protected
     * @return {import("../Feature.js").default} Feature.
     */
    TextFeature.prototype.readFeatureFromText = function (text, opt_options) {
        return abstract();
    };
    /**
     * Read the features from the source.
     *
     * @param {Document|Element|Object|string} source Source.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @return {Array<import("../Feature.js").default>} Features.
     * @api
     */
    TextFeature.prototype.readFeatures = function (source, opt_options) {
        return this.readFeaturesFromText(getText(source), this.adaptOptions(opt_options));
    };
    /**
     * @abstract
     * @param {string} text Text.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @protected
     * @return {Array<import("../Feature.js").default>} Features.
     */
    TextFeature.prototype.readFeaturesFromText = function (text, opt_options) {
        return abstract();
    };
    /**
     * Read the geometry from the source.
     *
     * @param {Document|Element|Object|string} source Source.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @return {import("../geom/Geometry.js").default} Geometry.
     * @api
     */
    TextFeature.prototype.readGeometry = function (source, opt_options) {
        return this.readGeometryFromText(getText(source), this.adaptOptions(opt_options));
    };
    /**
     * @abstract
     * @param {string} text Text.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @protected
     * @return {import("../geom/Geometry.js").default} Geometry.
     */
    TextFeature.prototype.readGeometryFromText = function (text, opt_options) {
        return abstract();
    };
    /**
     * Read the projection from the source.
     *
     * @param {Document|Element|Object|string} source Source.
     * @return {import("../proj/Projection.js").default} Projection.
     * @api
     */
    TextFeature.prototype.readProjection = function (source) {
        return this.readProjectionFromText(getText(source));
    };
    /**
     * @param {string} text Text.
     * @protected
     * @return {import("../proj/Projection.js").default} Projection.
     */
    TextFeature.prototype.readProjectionFromText = function (text) {
        return this.dataProjection;
    };
    /**
     * Encode a feature as a string.
     *
     * @param {import("../Feature.js").default} feature Feature.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @return {string} Encoded feature.
     * @api
     */
    TextFeature.prototype.writeFeature = function (feature, opt_options) {
        return this.writeFeatureText(feature, this.adaptOptions(opt_options));
    };
    /**
     * @abstract
     * @param {import("../Feature.js").default} feature Features.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @protected
     * @return {string} Text.
     */
    TextFeature.prototype.writeFeatureText = function (feature, opt_options) {
        return abstract();
    };
    /**
     * Encode an array of features as string.
     *
     * @param {Array<import("../Feature.js").default>} features Features.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @return {string} Encoded features.
     * @api
     */
    TextFeature.prototype.writeFeatures = function (features, opt_options) {
        return this.writeFeaturesText(features, this.adaptOptions(opt_options));
    };
    /**
     * @abstract
     * @param {Array<import("../Feature.js").default>} features Features.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @protected
     * @return {string} Text.
     */
    TextFeature.prototype.writeFeaturesText = function (features, opt_options) {
        return abstract();
    };
    /**
     * Write a single geometry.
     *
     * @param {import("../geom/Geometry.js").default} geometry Geometry.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @return {string} Geometry.
     * @api
     */
    TextFeature.prototype.writeGeometry = function (geometry, opt_options) {
        return this.writeGeometryText(geometry, this.adaptOptions(opt_options));
    };
    /**
     * @abstract
     * @param {import("../geom/Geometry.js").default} geometry Geometry.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @protected
     * @return {string} Text.
     */
    TextFeature.prototype.writeGeometryText = function (geometry, opt_options) {
        return abstract();
    };
    return TextFeature;
}(FeatureFormat));
/**
 * @param {Document|Element|Object|string} source Source.
 * @return {string} Text.
 */
function getText(source) {
    if (typeof source === 'string') {
        return source;
    }
    else {
        return '';
    }
}

var __extends$6 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * Geometry constructors
 * @enum {function (new:import("../geom/Geometry.js").default, Array, import("../geom/GeometryLayout.js").default)}
 */
var GeometryConstructor = {
    'POINT': Point,
    'LINESTRING': LineString,
    'POLYGON': Polygon,
    'MULTIPOINT': MultiPoint,
    'MULTILINESTRING': MultiLineString,
    'MULTIPOLYGON': MultiPolygon,
};
/**
 * @typedef {Object} Options
 * @property {boolean} [splitCollection=false] Whether to split GeometryCollections into
 * multiple features on reading.
 */
/**
 * @typedef {Object} Token
 * @property {number} type
 * @property {number|string} [value]
 * @property {number} position
 */
/**
 * @const
 * @type {string}
 */
var EMPTY = 'EMPTY';
/**
 * @const
 * @type {string}
 */
var Z = 'Z';
/**
 * @const
 * @type {string}
 */
var M = 'M';
/**
 * @const
 * @type {string}
 */
var ZM = 'ZM';
/**
 * @const
 * @enum {number}
 */
var TokenType = {
    TEXT: 1,
    LEFT_PAREN: 2,
    RIGHT_PAREN: 3,
    NUMBER: 4,
    COMMA: 5,
    EOF: 6,
};
for (var type in GeometryType) {
    GeometryType[type].toUpperCase();
}
/**
 * Class to tokenize a WKT string.
 */
var Lexer = /** @class */ (function () {
    /**
     * @param {string} wkt WKT string.
     */
    function Lexer(wkt) {
        /**
         * @type {string}
         */
        this.wkt = wkt;
        /**
         * @type {number}
         * @private
         */
        this.index_ = -1;
    }
    /**
     * @param {string} c Character.
     * @return {boolean} Whether the character is alphabetic.
     * @private
     */
    Lexer.prototype.isAlpha_ = function (c) {
        return (c >= 'a' && c <= 'z') || (c >= 'A' && c <= 'Z');
    };
    /**
     * @param {string} c Character.
     * @param {boolean=} opt_decimal Whether the string number
     *     contains a dot, i.e. is a decimal number.
     * @return {boolean} Whether the character is numeric.
     * @private
     */
    Lexer.prototype.isNumeric_ = function (c, opt_decimal) {
        var decimal = opt_decimal !== undefined ? opt_decimal : false;
        return (c >= '0' && c <= '9') || (c == '.' && !decimal);
    };
    /**
     * @param {string} c Character.
     * @return {boolean} Whether the character is whitespace.
     * @private
     */
    Lexer.prototype.isWhiteSpace_ = function (c) {
        return c == ' ' || c == '\t' || c == '\r' || c == '\n';
    };
    /**
     * @return {string} Next string character.
     * @private
     */
    Lexer.prototype.nextChar_ = function () {
        return this.wkt.charAt(++this.index_);
    };
    /**
     * Fetch and return the next token.
     * @return {!Token} Next string token.
     */
    Lexer.prototype.nextToken = function () {
        var c = this.nextChar_();
        var position = this.index_;
        /** @type {number|string} */
        var value = c;
        var type;
        if (c == '(') {
            type = TokenType.LEFT_PAREN;
        }
        else if (c == ',') {
            type = TokenType.COMMA;
        }
        else if (c == ')') {
            type = TokenType.RIGHT_PAREN;
        }
        else if (this.isNumeric_(c) || c == '-') {
            type = TokenType.NUMBER;
            value = this.readNumber_();
        }
        else if (this.isAlpha_(c)) {
            type = TokenType.TEXT;
            value = this.readText_();
        }
        else if (this.isWhiteSpace_(c)) {
            return this.nextToken();
        }
        else if (c === '') {
            type = TokenType.EOF;
        }
        else {
            throw new Error('Unexpected character: ' + c);
        }
        return { position: position, value: value, type: type };
    };
    /**
     * @return {number} Numeric token value.
     * @private
     */
    Lexer.prototype.readNumber_ = function () {
        var c;
        var index = this.index_;
        var decimal = false;
        var scientificNotation = false;
        do {
            if (c == '.') {
                decimal = true;
            }
            else if (c == 'e' || c == 'E') {
                scientificNotation = true;
            }
            c = this.nextChar_();
        } while (this.isNumeric_(c, decimal) ||
            // if we haven't detected a scientific number before, 'e' or 'E'
            // hint that we should continue to read
            (!scientificNotation && (c == 'e' || c == 'E')) ||
            // once we know that we have a scientific number, both '-' and '+'
            // are allowed
            (scientificNotation && (c == '-' || c == '+')));
        return parseFloat(this.wkt.substring(index, this.index_--));
    };
    /**
     * @return {string} String token value.
     * @private
     */
    Lexer.prototype.readText_ = function () {
        var c;
        var index = this.index_;
        do {
            c = this.nextChar_();
        } while (this.isAlpha_(c));
        return this.wkt.substring(index, this.index_--).toUpperCase();
    };
    return Lexer;
}());
/**
 * Class to parse the tokens from the WKT string.
 */
var Parser = /** @class */ (function () {
    /**
     * @param {Lexer} lexer The lexer.
     */
    function Parser(lexer) {
        /**
         * @type {Lexer}
         * @private
         */
        this.lexer_ = lexer;
        /**
         * @type {Token}
         * @private
         */
        this.token_;
        /**
         * @type {import("../geom/GeometryLayout.js").default}
         * @private
         */
        this.layout_ = GeometryLayout.XY;
    }
    /**
     * Fetch the next token form the lexer and replace the active token.
     * @private
     */
    Parser.prototype.consume_ = function () {
        this.token_ = this.lexer_.nextToken();
    };
    /**
     * Tests if the given type matches the type of the current token.
     * @param {TokenType} type Token type.
     * @return {boolean} Whether the token matches the given type.
     */
    Parser.prototype.isTokenType = function (type) {
        var isMatch = this.token_.type == type;
        return isMatch;
    };
    /**
     * If the given type matches the current token, consume it.
     * @param {TokenType} type Token type.
     * @return {boolean} Whether the token matches the given type.
     */
    Parser.prototype.match = function (type) {
        var isMatch = this.isTokenType(type);
        if (isMatch) {
            this.consume_();
        }
        return isMatch;
    };
    /**
     * Try to parse the tokens provided by the lexer.
     * @return {import("../geom/Geometry.js").default} The geometry.
     */
    Parser.prototype.parse = function () {
        this.consume_();
        var geometry = this.parseGeometry_();
        return geometry;
    };
    /**
     * Try to parse the dimensional info.
     * @return {import("../geom/GeometryLayout.js").default} The layout.
     * @private
     */
    Parser.prototype.parseGeometryLayout_ = function () {
        var layout = GeometryLayout.XY;
        var dimToken = this.token_;
        if (this.isTokenType(TokenType.TEXT)) {
            var dimInfo = dimToken.value;
            if (dimInfo === Z) {
                layout = GeometryLayout.XYZ;
            }
            else if (dimInfo === M) {
                layout = GeometryLayout.XYM;
            }
            else if (dimInfo === ZM) {
                layout = GeometryLayout.XYZM;
            }
            if (layout !== GeometryLayout.XY) {
                this.consume_();
            }
        }
        return layout;
    };
    /**
     * @return {!Array<import("../geom/Geometry.js").default>} A collection of geometries.
     * @private
     */
    Parser.prototype.parseGeometryCollectionText_ = function () {
        if (this.match(TokenType.LEFT_PAREN)) {
            var geometries = [];
            do {
                geometries.push(this.parseGeometry_());
            } while (this.match(TokenType.COMMA));
            if (this.match(TokenType.RIGHT_PAREN)) {
                return geometries;
            }
        }
        else if (this.isEmptyGeometry_()) {
            return [];
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {Array<number>} All values in a point.
     * @private
     */
    Parser.prototype.parsePointText_ = function () {
        if (this.match(TokenType.LEFT_PAREN)) {
            var coordinates = this.parsePoint_();
            if (this.match(TokenType.RIGHT_PAREN)) {
                return coordinates;
            }
        }
        else if (this.isEmptyGeometry_()) {
            return null;
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {!Array<!Array<number>>} All points in a linestring.
     * @private
     */
    Parser.prototype.parseLineStringText_ = function () {
        if (this.match(TokenType.LEFT_PAREN)) {
            var coordinates = this.parsePointList_();
            if (this.match(TokenType.RIGHT_PAREN)) {
                return coordinates;
            }
        }
        else if (this.isEmptyGeometry_()) {
            return [];
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {!Array<!Array<!Array<number>>>} All points in a polygon.
     * @private
     */
    Parser.prototype.parsePolygonText_ = function () {
        if (this.match(TokenType.LEFT_PAREN)) {
            var coordinates = this.parseLineStringTextList_();
            if (this.match(TokenType.RIGHT_PAREN)) {
                return coordinates;
            }
        }
        else if (this.isEmptyGeometry_()) {
            return [];
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {!Array<!Array<number>>} All points in a multipoint.
     * @private
     */
    Parser.prototype.parseMultiPointText_ = function () {
        if (this.match(TokenType.LEFT_PAREN)) {
            var coordinates = void 0;
            if (this.token_.type == TokenType.LEFT_PAREN) {
                coordinates = this.parsePointTextList_();
            }
            else {
                coordinates = this.parsePointList_();
            }
            if (this.match(TokenType.RIGHT_PAREN)) {
                return coordinates;
            }
        }
        else if (this.isEmptyGeometry_()) {
            return [];
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {!Array<!Array<!Array<number>>>} All linestring points
     *                                          in a multilinestring.
     * @private
     */
    Parser.prototype.parseMultiLineStringText_ = function () {
        if (this.match(TokenType.LEFT_PAREN)) {
            var coordinates = this.parseLineStringTextList_();
            if (this.match(TokenType.RIGHT_PAREN)) {
                return coordinates;
            }
        }
        else if (this.isEmptyGeometry_()) {
            return [];
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {!Array<!Array<!Array<!Array<number>>>>} All polygon points in a multipolygon.
     * @private
     */
    Parser.prototype.parseMultiPolygonText_ = function () {
        if (this.match(TokenType.LEFT_PAREN)) {
            var coordinates = this.parsePolygonTextList_();
            if (this.match(TokenType.RIGHT_PAREN)) {
                return coordinates;
            }
        }
        else if (this.isEmptyGeometry_()) {
            return [];
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {!Array<number>} A point.
     * @private
     */
    Parser.prototype.parsePoint_ = function () {
        var coordinates = [];
        var dimensions = this.layout_.length;
        for (var i = 0; i < dimensions; ++i) {
            var token = this.token_;
            if (this.match(TokenType.NUMBER)) {
                coordinates.push(/** @type {number} */ (token.value));
            }
            else {
                break;
            }
        }
        if (coordinates.length == dimensions) {
            return coordinates;
        }
        throw new Error(this.formatErrorMessage_());
    };
    /**
     * @return {!Array<!Array<number>>} An array of points.
     * @private
     */
    Parser.prototype.parsePointList_ = function () {
        var coordinates = [this.parsePoint_()];
        while (this.match(TokenType.COMMA)) {
            coordinates.push(this.parsePoint_());
        }
        return coordinates;
    };
    /**
     * @return {!Array<!Array<number>>} An array of points.
     * @private
     */
    Parser.prototype.parsePointTextList_ = function () {
        var coordinates = [this.parsePointText_()];
        while (this.match(TokenType.COMMA)) {
            coordinates.push(this.parsePointText_());
        }
        return coordinates;
    };
    /**
     * @return {!Array<!Array<!Array<number>>>} An array of points.
     * @private
     */
    Parser.prototype.parseLineStringTextList_ = function () {
        var coordinates = [this.parseLineStringText_()];
        while (this.match(TokenType.COMMA)) {
            coordinates.push(this.parseLineStringText_());
        }
        return coordinates;
    };
    /**
     * @return {!Array<!Array<!Array<!Array<number>>>>} An array of points.
     * @private
     */
    Parser.prototype.parsePolygonTextList_ = function () {
        var coordinates = [this.parsePolygonText_()];
        while (this.match(TokenType.COMMA)) {
            coordinates.push(this.parsePolygonText_());
        }
        return coordinates;
    };
    /**
     * @return {boolean} Whether the token implies an empty geometry.
     * @private
     */
    Parser.prototype.isEmptyGeometry_ = function () {
        var isEmpty = this.isTokenType(TokenType.TEXT) && this.token_.value == EMPTY;
        if (isEmpty) {
            this.consume_();
        }
        return isEmpty;
    };
    /**
     * Create an error message for an unexpected token error.
     * @return {string} Error message.
     * @private
     */
    Parser.prototype.formatErrorMessage_ = function () {
        return ('Unexpected `' +
            this.token_.value +
            '` at position ' +
            this.token_.position +
            ' in `' +
            this.lexer_.wkt +
            '`');
    };
    /**
     * @return {!import("../geom/Geometry.js").default} The geometry.
     * @private
     */
    Parser.prototype.parseGeometry_ = function () {
        var token = this.token_;
        if (this.match(TokenType.TEXT)) {
            var geomType = token.value;
            this.layout_ = this.parseGeometryLayout_();
            if (geomType == 'GEOMETRYCOLLECTION') {
                var geometries = this.parseGeometryCollectionText_();
                return new GeometryCollection(geometries);
            }
            else {
                var ctor = GeometryConstructor[geomType];
                if (!ctor) {
                    throw new Error('Invalid geometry type: ' + geomType);
                }
                var coordinates = void 0;
                switch (geomType) {
                    case 'POINT': {
                        coordinates = this.parsePointText_();
                        break;
                    }
                    case 'LINESTRING': {
                        coordinates = this.parseLineStringText_();
                        break;
                    }
                    case 'POLYGON': {
                        coordinates = this.parsePolygonText_();
                        break;
                    }
                    case 'MULTIPOINT': {
                        coordinates = this.parseMultiPointText_();
                        break;
                    }
                    case 'MULTILINESTRING': {
                        coordinates = this.parseMultiLineStringText_();
                        break;
                    }
                    case 'MULTIPOLYGON': {
                        coordinates = this.parseMultiPolygonText_();
                        break;
                    }
                    default: {
                        throw new Error('Invalid geometry type: ' + geomType);
                    }
                }
                if (!coordinates) {
                    if (ctor === GeometryConstructor['POINT']) {
                        coordinates = [NaN, NaN];
                    }
                    else {
                        coordinates = [];
                    }
                }
                return new ctor(coordinates, this.layout_);
            }
        }
        throw new Error(this.formatErrorMessage_());
    };
    return Parser;
}());
/**
 * @classdesc
 * Geometry format for reading and writing data in the `WellKnownText` (WKT)
 * format.
 *
 * @api
 */
var WKT = /** @class */ (function (_super) {
    __extends$6(WKT, _super);
    /**
     * @param {Options=} opt_options Options.
     */
    function WKT(opt_options) {
        var _this = _super.call(this) || this;
        var options = opt_options ? opt_options : {};
        /**
         * Split GeometryCollection into multiple features.
         * @type {boolean}
         * @private
         */
        _this.splitCollection_ =
            options.splitCollection !== undefined ? options.splitCollection : false;
        return _this;
    }
    /**
     * Parse a WKT string.
     * @param {string} wkt WKT string.
     * @return {import("../geom/Geometry.js").default|undefined}
     *     The geometry created.
     * @private
     */
    WKT.prototype.parse_ = function (wkt) {
        var lexer = new Lexer(wkt);
        var parser = new Parser(lexer);
        return parser.parse();
    };
    /**
     * @protected
     * @param {string} text Text.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @return {import("../Feature.js").default} Feature.
     */
    WKT.prototype.readFeatureFromText = function (text, opt_options) {
        var geom = this.readGeometryFromText(text, opt_options);
        if (geom) {
            var feature = new Feature();
            feature.setGeometry(geom);
            return feature;
        }
        return null;
    };
    /**
     * @param {string} text Text.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @protected
     * @return {Array<Feature>} Features.
     */
    WKT.prototype.readFeaturesFromText = function (text, opt_options) {
        var geometries = [];
        var geometry = this.readGeometryFromText(text, opt_options);
        if (this.splitCollection_ &&
            geometry.getType() == GeometryType.GEOMETRY_COLLECTION) {
            geometries = /** @type {GeometryCollection} */ (geometry).getGeometriesArray();
        }
        else {
            geometries = [geometry];
        }
        var features = [];
        for (var i = 0, ii = geometries.length; i < ii; ++i) {
            var feature = new Feature();
            feature.setGeometry(geometries[i]);
            features.push(feature);
        }
        return features;
    };
    /**
     * @param {string} text Text.
     * @param {import("./Feature.js").ReadOptions=} opt_options Read options.
     * @protected
     * @return {import("../geom/Geometry.js").default} Geometry.
     */
    WKT.prototype.readGeometryFromText = function (text, opt_options) {
        var geometry = this.parse_(text);
        if (geometry) {
            return transformGeometryWithOptions(geometry, false, opt_options);
        }
        else {
            return null;
        }
    };
    /**
     * @param {import("../Feature.js").default} feature Features.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @protected
     * @return {string} Text.
     */
    WKT.prototype.writeFeatureText = function (feature, opt_options) {
        var geometry = feature.getGeometry();
        if (geometry) {
            return this.writeGeometryText(geometry, opt_options);
        }
        return '';
    };
    /**
     * @param {Array<import("../Feature.js").default>} features Features.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @protected
     * @return {string} Text.
     */
    WKT.prototype.writeFeaturesText = function (features, opt_options) {
        if (features.length == 1) {
            return this.writeFeatureText(features[0], opt_options);
        }
        var geometries = [];
        for (var i = 0, ii = features.length; i < ii; ++i) {
            geometries.push(features[i].getGeometry());
        }
        var collection = new GeometryCollection(geometries);
        return this.writeGeometryText(collection, opt_options);
    };
    /**
     * @param {import("../geom/Geometry.js").default} geometry Geometry.
     * @param {import("./Feature.js").WriteOptions=} opt_options Write options.
     * @protected
     * @return {string} Text.
     */
    WKT.prototype.writeGeometryText = function (geometry, opt_options) {
        return encode(transformGeometryWithOptions(geometry, true, opt_options));
    };
    return WKT;
}(TextFeature));
/**
 * @param {Point} geom Point geometry.
 * @return {string} Coordinates part of Point as WKT.
 */
function encodePointGeometry(geom) {
    var coordinates = geom.getCoordinates();
    if (coordinates.length === 0) {
        return '';
    }
    return coordinates.join(' ');
}
/**
 * @param {MultiPoint} geom MultiPoint geometry.
 * @return {string} Coordinates part of MultiPoint as WKT.
 */
function encodeMultiPointGeometry(geom) {
    var array = [];
    var components = geom.getPoints();
    for (var i = 0, ii = components.length; i < ii; ++i) {
        array.push('(' + encodePointGeometry(components[i]) + ')');
    }
    return array.join(',');
}
/**
 * @param {GeometryCollection} geom GeometryCollection geometry.
 * @return {string} Coordinates part of GeometryCollection as WKT.
 */
function encodeGeometryCollectionGeometry(geom) {
    var array = [];
    var geoms = geom.getGeometries();
    for (var i = 0, ii = geoms.length; i < ii; ++i) {
        array.push(encode(geoms[i]));
    }
    return array.join(',');
}
/**
 * @param {LineString|import("../geom/LinearRing.js").default} geom LineString geometry.
 * @return {string} Coordinates part of LineString as WKT.
 */
function encodeLineStringGeometry(geom) {
    var coordinates = geom.getCoordinates();
    var array = [];
    for (var i = 0, ii = coordinates.length; i < ii; ++i) {
        array.push(coordinates[i].join(' '));
    }
    return array.join(',');
}
/**
 * @param {MultiLineString} geom MultiLineString geometry.
 * @return {string} Coordinates part of MultiLineString as WKT.
 */
function encodeMultiLineStringGeometry(geom) {
    var array = [];
    var components = geom.getLineStrings();
    for (var i = 0, ii = components.length; i < ii; ++i) {
        array.push('(' + encodeLineStringGeometry(components[i]) + ')');
    }
    return array.join(',');
}
/**
 * @param {Polygon} geom Polygon geometry.
 * @return {string} Coordinates part of Polygon as WKT.
 */
function encodePolygonGeometry(geom) {
    var array = [];
    var rings = geom.getLinearRings();
    for (var i = 0, ii = rings.length; i < ii; ++i) {
        array.push('(' + encodeLineStringGeometry(rings[i]) + ')');
    }
    return array.join(',');
}
/**
 * @param {MultiPolygon} geom MultiPolygon geometry.
 * @return {string} Coordinates part of MultiPolygon as WKT.
 */
function encodeMultiPolygonGeometry(geom) {
    var array = [];
    var components = geom.getPolygons();
    for (var i = 0, ii = components.length; i < ii; ++i) {
        array.push('(' + encodePolygonGeometry(components[i]) + ')');
    }
    return array.join(',');
}
/**
 * @param {import("../geom/SimpleGeometry.js").default} geom SimpleGeometry geometry.
 * @return {string} Potential dimensional information for WKT type.
 */
function encodeGeometryLayout(geom) {
    var layout = geom.getLayout();
    var dimInfo = '';
    if (layout === GeometryLayout.XYZ || layout === GeometryLayout.XYZM) {
        dimInfo += Z;
    }
    if (layout === GeometryLayout.XYM || layout === GeometryLayout.XYZM) {
        dimInfo += M;
    }
    return dimInfo;
}
/**
 * @const
 * @type {Object<string, function(import("../geom/Geometry.js").default): string>}
 */
var GeometryEncoder = {
    'Point': encodePointGeometry,
    'LineString': encodeLineStringGeometry,
    'Polygon': encodePolygonGeometry,
    'MultiPoint': encodeMultiPointGeometry,
    'MultiLineString': encodeMultiLineStringGeometry,
    'MultiPolygon': encodeMultiPolygonGeometry,
    'GeometryCollection': encodeGeometryCollectionGeometry,
};
/**
 * Encode a geometry as WKT.
 * @param {!import("../geom/Geometry.js").default} geom The geometry to encode.
 * @return {string} WKT string for the geometry.
 */
function encode(geom) {
    var type = geom.getType();
    var geometryEncoder = GeometryEncoder[type];
    var enc = geometryEncoder(geom);
    type = type.toUpperCase();
    if (typeof ( /** @type {?} */(geom).getFlatCoordinates) === 'function') {
        var dimInfo = encodeGeometryLayout(
        /** @type {import("../geom/SimpleGeometry.js").default} */ (geom));
        if (dimInfo.length > 0) {
            type += ' ' + dimInfo;
        }
    }
    if (enc.length === 0) {
        return type + ' ' + EMPTY;
    }
    return type + '(' + enc + ')';
}

export { WKT };
