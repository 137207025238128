import * as d3 from "../_snowpack/pkg/d3.js";

export async function blobToString(blob) {
   const fileReader = new FileReader();
   return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
         resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
   });
}

export function deserializeReportDataRules(key, value) {
   if (key === "ipc" && (value === null || value === undefined)) {
      return 0;
   }
   else if (key === "tomr") {
      return d3.isoParse(value)
   }

   return value;
}

export function assignFlightIndex(flightArray) {
   let index = 0;
   flightArray.forEach(
      flight => flight.history.forEach(data => {
         data.index = index++;
      })
   );

   return flightArray
}

export function getFlightData(flightArray, index) {
   let i = 0;
   let rollingSum = 0;
   while (i < flightArray.length) {
      rollingSum += flightArray[i].history.length

      if (rollingSum > index) {
         let internalIndex = index - rollingSum + flightArray[i].history.length;
         return flightArray[i].history[internalIndex];
      }

      i++;
   }

   return null;
}

export const formatByMinute = d3.utcFormat("%Y-%m-%d %H:%M:%S")
export const formatDate = d3.utcFormat("%Y-%m-%d %H:%M:%S.%L")