/* src/Axis.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	detach,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element
} from "../_snowpack/pkg/svelte/internal.js";

import * as d3 from "../_snowpack/pkg/d3.js";
import { select, selectAll } from "../_snowpack/pkg/d3-selection.js";
import { axisBottom, axisLeft } from "../_snowpack/pkg/d3-axis.js";

function create_fragment(ctx) {
	let g_1;

	return {
		c() {
			g_1 = svg_element("g");
			attr(g_1, "class", "axis");
			attr(g_1, "transform", /*transform*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, g_1, anchor);
			/*g_1_binding*/ ctx[9](g_1);
		},
		p(ctx, [dirty]) {
			if (dirty & /*transform*/ 2) {
				attr(g_1, "transform", /*transform*/ ctx[1]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(g_1);
			/*g_1_binding*/ ctx[9](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { width } = $$props;
	let { height } = $$props;
	let { margin } = $$props;
	let { position } = $$props;
	let { scale } = $$props;
	let { color = "white" } = $$props;
	let { integersOnly = false } = $$props;
	let transform;
	let g;

	function g_1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			g = $$value;
			$$invalidate(0, g);
		});
	}

	$$self.$$set = $$props => {
		if ("width" in $$props) $$invalidate(2, width = $$props.width);
		if ("height" in $$props) $$invalidate(3, height = $$props.height);
		if ("margin" in $$props) $$invalidate(4, margin = $$props.margin);
		if ("position" in $$props) $$invalidate(5, position = $$props.position);
		if ("scale" in $$props) $$invalidate(6, scale = $$props.scale);
		if ("color" in $$props) $$invalidate(7, color = $$props.color);
		if ("integersOnly" in $$props) $$invalidate(8, integersOnly = $$props.integersOnly);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*g, position, scale, height, margin, integersOnly*/ 377) {
			$: {
				select(g).selectAll("*").remove();
				let axis;

				switch (position) {
					case "bottom":
						axis = axisBottom(scale).tickSizeOuter(0);
						$$invalidate(1, transform = `translate(0, ${height - margin})`);
						break;
					case "left":
						const yAxisTicks = scale.ticks().filter(tick => Number.isInteger(tick));
						axis = axisLeft(scale).tickSizeOuter(0);
						if (integersOnly) {
							axis = axis.tickValues(yAxisTicks).tickFormat(d3.format("d"));
						}
						$$invalidate(1, transform = `translate(${margin}, 0)`);
				}

				select(g).call(axis);
			}
		}
	};

	return [
		g,
		transform,
		width,
		height,
		margin,
		position,
		scale,
		color,
		integersOnly,
		g_1_binding
	];
}

class Axis extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			width: 2,
			height: 3,
			margin: 4,
			position: 5,
			scale: 6,
			color: 7,
			integersOnly: 8
		});
	}
}

export default Axis;