/* src/App.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import EventReport from "./EventReport.svelte.js";
import Error from "./Error.svelte.js";

function create_else_block(ctx) {
	let error;
	let current;
	error = new Error({});

	return {
		c() {
			create_component(error.$$.fragment);
		},
		m(target, anchor) {
			mount_component(error, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(error.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(error.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(error, detaching);
		}
	};
}

// (13:3) {#if year && month && day && file}
function create_if_block(ctx) {
	let eventreport;
	let current;

	eventreport = new EventReport({
			props: {
				year: /*year*/ ctx[0],
				month: /*month*/ ctx[1],
				day: /*day*/ ctx[2],
				file: /*file*/ ctx[3],
				sas: window.location.search
			}
		});

	return {
		c() {
			create_component(eventreport.$$.fragment);
		},
		m(target, anchor) {
			mount_component(eventreport, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(eventreport.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(eventreport.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(eventreport, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*year*/ ctx[0] && /*month*/ ctx[1] && /*day*/ ctx[2] && /*file*/ ctx[3]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if_block.p(ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self) {
	const urlParams = new URLSearchParams(window.location.search);
	const year = urlParams.get("year");
	const month = urlParams.get("month");
	const day = urlParams.get("day");
	const file = urlParams.get("file");
	return [year, month, day, file];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;