import App from './App.svelte.js';

Array.prototype.groupBy = function(prop, func) {
   return this.reduce(function(groups, item) {
      const val = func(item[prop])
      groups[val] = groups[val] || []
      groups[val].push(item)
      return groups
   }, {})
}

const app = new App({
   target: document.body,
});

export default app;