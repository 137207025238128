import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import './EventMap.svelte.css';
/* src/EventMap.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../_snowpack/pkg/svelte/internal.js";

import { reportType, flights, selected } from "./TargetDataStore.js";
import "../_snowpack/pkg/ol/ol.css";
import VectorSource from "../_snowpack/pkg/ol/source/Vector.js";
import { Feature, Map, Overlay, View } from "../_snowpack/pkg/ol.js";
import TileLayer from "../_snowpack/pkg/ol/layer/Tile.js";
import XYZ from "../_snowpack/pkg/ol/source/XYZ.js";
import VectorLayer from "../_snowpack/pkg/ol/layer/Vector.js";
import Point from "../_snowpack/pkg/ol/geom/Point.js";
import { transform } from "../_snowpack/pkg/ol/proj.js";
import { onMount } from "../_snowpack/pkg/svelte.js";
import Popup from "../_snowpack/pkg/ol-popup.js";
import { Style, Circle, Fill, Stroke } from "../_snowpack/pkg/ol/style.js";
import { getFlightData } from "./Utils.js";
import { WKT } from "../_snowpack/pkg/ol/format.js";

function create_fragment(ctx) {
	let div1;

	return {
		c() {
			div1 = element("div");
			div1.innerHTML = `<div id="map" class="map svelte-gfv581"></div>`;
			attr(div1, "id", "mapContainer");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
		}
	};
}

let selectedPoint = null;

function instance($$self, $$props, $$invalidate) {
	let $flights;
	component_subscribe($$self, flights, $$value => $$invalidate(3, $flights = $$value));
	let { polygonString = "" } = $$props;
	const format = new WKT();
	const polygonSource = new VectorSource({});
	const { SNOWPACK_PUBLIC_MAP_URL } = __SNOWPACK_ENV__;
	let latLonPointSource = new VectorSource({ wrapX: true });
	let eventPointSource = new VectorSource({ wrapX: true });
	let selectedSource = new VectorSource({ wrapX: true });
	let popup = new Popup({ autoPan: false });
	let map;

	const dataPointStyling = new Style({
			image: new Circle({
					radius: 5,
					fill: new Fill({ color: "#5aa7e6" }),
					stroke: new Stroke({ color: "white", width: 1 })
				})
		});

	const picEventStyling = new Style({
			image: new Circle({
					radius: 5,
					fill: new Fill({ color: "#e67c2c" }),
					stroke: new Stroke({ color: "white", width: 1 })
				})
		});

	const ipcEventStyling = new Style({
			image: new Circle({
					radius: 5,
					fill: new Fill({ color: "#cc2b2b" }),
					stroke: new Stroke({ color: "white", width: 1 })
				})
		});

	const selectedStyling = new Style({
			image: new Circle({
					radius: 6,
					fill: new Fill({ color: "rgba(255,255,255,0.3)" }),
					stroke: new Stroke({ color: "white", width: 3 })
				})
		});

	function eventPointStyling(point) {
		let data = point.data;

		return data.ipc === 1
		? ipcEventStyling
		: data.pic < 7 ? picEventStyling : dataPointStyling;
	}

	function loadDataPoints(flights) {
		latLonPointSource.clear();

		latLonPointSource.addFeatures(flights.flatMap(flight => flight.history.map(data => {
			let feature = new Feature(new Point(transform([data.lon, data.lat], "EPSG:4326", "EPSG:3857")));
			data.icao = flight.icao;
			feature.index = data.index;
			feature.event = false;
			return feature;
		})));
	}

	function loadEventPoints(flights) {
		eventPointSource.clear();

		eventPointSource.addFeatures(flights.flatMap(flight => flight.events.map(data => {
			let feature = new Feature(new Point(transform([data.lon, data.lat], "EPSG:4326", "EPSG:3857")));
			feature.data = { ipc: data.ipc, pic: data.pic };
			feature.event = true;
			return feature;
		})));
	}

	let firstLoad = true;

	function zoomOnFeatureOnLoad() {
		if (firstLoad && map !== undefined) {
			firstLoad = false;
			let feature1 = latLonPointSource.getFeatures().map(d => d.getGeometry().getCoordinates());
			let xs = feature1.map(d => d[0]);
			let ys = feature1.map(d => d[1]);
			let extent = [Math.min(...xs), Math.min(...ys), Math.max(...xs), Math.max(...ys)];
			map.getView().fit(extent, { padding: [64, 64, 64, 64] });
		}
	}

	function intializeMap() {
		map = new Map({
				target: "map",
				overlays: [popup],
				layers: [
					new TileLayer({
							source: new XYZ({ url: SNOWPACK_PUBLIC_MAP_URL })
						}),
					new VectorLayer({ source: polygonSource }),
					new VectorLayer({
							source: latLonPointSource,
							style: dataPointStyling
						}),
					new VectorLayer({
							source: eventPointSource,
							style: eventPointStyling
						}),
					new VectorLayer({
							source: selectedSource,
							style: selectedStyling
						})
				],
				view: new View({ center: [0, 0], zoom: 2 })
			});

		map.on("pointermove", function (event) {
			if (map.hasFeatureAtPixel(event.pixel)) {
				var feature = map.getFeaturesAtPixel(event.pixel).find(f => f.event === false);

				if (feature !== undefined) {
					selected.set(feature.index);
				}
			} else {
				selected.set(-1);
				selectedSource.clear();
				popup.hide();
				map.getViewport().style.cursor = "inherit";
			}
		});
	}

	flights.subscribe(allFlights => {
		loadDataPoints(allFlights);
		loadEventPoints(allFlights);
		zoomOnFeatureOnLoad();
	});

	onMount(() => {
		intializeMap();
	});

	selected.subscribe(index => {
		if (map !== undefined) {
			let featureData = getFlightData($flights, index);

			if (index !== -1 && featureData !== null) {
				const { lat, lon, acid, tomr, alt, groundSpeed, verticalRate, pic, ipc } = featureData;

				let text = `Aircraft ID: ${acid}
            Altitude: ${alt ?? "---"}ft
            Speed: ${groundSpeed?.toFixed(2) ?? "---"}kt
            VerticalRate: ${verticalRate?.toFixed(2) ?? "---"}ft/min
            PIC: ${pic}
            IPC: ${ipc}
            ${tomr.toISOString()}`;

				text = text.replaceAll("\n", "<br/>");
				let highlightedFeature = new Feature(new Point(transform([lon, lat], "EPSG:4326", "EPSG:3857")));
				var coordinate = highlightedFeature.getGeometry().getCoordinates();
				popup.show(coordinate, `<div class="map-tooltip mdc-elevation--z6">${text}</div>`);
				selectedSource.clear();
				selectedSource.addFeature(highlightedFeature);
				map.getViewport().style.cursor = "pointer";
			} else {
				selectedSource.clear();
				popup.hide();
				map.getViewport().style.cursor = "inherit";
			}
		}
	});

	$$self.$$set = $$props => {
		if ("polygonString" in $$props) $$invalidate(0, polygonString = $$props.polygonString);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*polygonString*/ 1) {
			$: if (polygonString !== "") {
				let feature = format.readFeature(polygonString, {
					dataProjection: "EPSG:4326",
					featureProjection: "EPSG:3857"
				});

				polygonSource.addFeature(feature);
			}
		}
	};

	return [polygonString];
}

class EventMap extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { polygonString: 0 });
	}
}

export default EventMap;