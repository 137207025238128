import './EventReportHeader.svelte.css';
/* src/EventReportHeader.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	add_render_callback,
	add_resize_listener,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div4;
	let div3;
	let div0;
	let t0;
	let div2;
	let div1;
	let t1;
	let br;
	let t2;
	let div3_resize_listener;

	return {
		c() {
			div4 = element("div");
			div3 = element("div");
			div0 = element("div");
			div0.innerHTML = `<img class="logo svelte-af4iu7" alt="Aireon Logo" src="assets/img/logo.svg"/>`;
			t0 = space();
			div2 = element("div");
			div1 = element("div");
			t1 = text(/*title*/ ctx[1]);
			br = element("br");
			t2 = text(/*subtitle*/ ctx[2]);
			attr(div0, "class", "outer svelte-af4iu7");
			attr(div1, "class", "info svelte-af4iu7");
			attr(div2, "class", "outer svelte-af4iu7");
			attr(div3, "class", "header mdc-elevation--z4 svelte-af4iu7");
			add_render_callback(() => /*div3_elementresize_handler*/ ctx[3].call(div3));
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div3);
			append(div3, div0);
			append(div3, t0);
			append(div3, div2);
			append(div2, div1);
			append(div1, t1);
			append(div1, br);
			append(div1, t2);
			div3_resize_listener = add_resize_listener(div3, /*div3_elementresize_handler*/ ctx[3].bind(div3));
		},
		p(ctx, [dirty]) {
			if (dirty & /*title*/ 2) set_data(t1, /*title*/ ctx[1]);
			if (dirty & /*subtitle*/ 4) set_data(t2, /*subtitle*/ ctx[2]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			div3_resize_listener();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { title = "unknown" } = $$props;
	let { subtitle = "unknown" } = $$props;
	let { height = 0 } = $$props;

	function div3_elementresize_handler() {
		height = this.clientHeight;
		$$invalidate(0, height);
	}

	$$self.$$set = $$props => {
		if ("title" in $$props) $$invalidate(1, title = $$props.title);
		if ("subtitle" in $$props) $$invalidate(2, subtitle = $$props.subtitle);
		if ("height" in $$props) $$invalidate(0, height = $$props.height);
	};

	return [height, title, subtitle, div3_elementresize_handler];
}

class EventReportHeader extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { title: 1, subtitle: 2, height: 0 });
	}
}

export default EventReportHeader;