import { I as ImageState } from './ImageState-51477cac.js';
import { a as abstract, c as assert } from './asserts-2fd060a4.js';
import { t as toSize } from './size-6b3b41da.js';
import { t as toString, b as asArray } from './color-33ef6dd3.js';
import { f as createCanvasContext2D } from './dom-f29f8bb7.js';
import { d as defaultFillStyle, e as defaultLineCap, h as defaultLineJoin, b as defaultStrokeStyle, i as defaultLineWidth, j as defaultMiterLimit } from './canvas-c98fcc61.js';

/**
 * @module ol/style/Fill
 */
/**
 * @typedef {Object} Options
 * @property {import("../color.js").Color|import("../colorlike.js").ColorLike} [color=null] A color, gradient or pattern.
 * See {@link module:ol/color~Color} and {@link module:ol/colorlike~ColorLike} for possible formats.
 * Default null; if null, the Canvas/renderer default black will be used.
 */
/**
 * @classdesc
 * Set fill style for vector features.
 * @api
 */
var Fill = /** @class */ (function () {
    /**
     * @param {Options=} opt_options Options.
     */
    function Fill(opt_options) {
        var options = opt_options || {};
        /**
         * @private
         * @type {import("../color.js").Color|import("../colorlike.js").ColorLike}
         */
        this.color_ = options.color !== undefined ? options.color : null;
    }
    /**
     * Clones the style. The color is not cloned if it is an {@link module:ol/colorlike~ColorLike}.
     * @return {Fill} The cloned style.
     * @api
     */
    Fill.prototype.clone = function () {
        var color = this.getColor();
        return new Fill({
            color: Array.isArray(color) ? color.slice() : color || undefined,
        });
    };
    /**
     * Get the fill color.
     * @return {import("../color.js").Color|import("../colorlike.js").ColorLike} Color.
     * @api
     */
    Fill.prototype.getColor = function () {
        return this.color_;
    };
    /**
     * Set the color.
     *
     * @param {import("../color.js").Color|import("../colorlike.js").ColorLike} color Color.
     * @api
     */
    Fill.prototype.setColor = function (color) {
        this.color_ = color;
    };
    return Fill;
}());

/**
 * @module ol/style/Stroke
 */
/**
 * @typedef {Object} Options
 * @property {import("../color.js").Color|import("../colorlike.js").ColorLike} [color] A color, gradient or pattern.
 * See {@link module:ol/color~Color} and {@link module:ol/colorlike~ColorLike} for possible formats.
 * Default null; if null, the Canvas/renderer default black will be used.
 * @property {CanvasLineCap} [lineCap='round'] Line cap style: `butt`, `round`, or `square`.
 * @property {CanvasLineJoin} [lineJoin='round'] Line join style: `bevel`, `round`, or `miter`.
 * @property {Array<number>} [lineDash] Line dash pattern. Default is `null` (no dash).
 * Please note that Internet Explorer 10 and lower do not support the `setLineDash` method on
 * the `CanvasRenderingContext2D` and therefore this option will have no visual effect in these browsers.
 * @property {number} [lineDashOffset=0] Line dash offset.
 * @property {number} [miterLimit=10] Miter limit.
 * @property {number} [width] Width.
 */
/**
 * @classdesc
 * Set stroke style for vector features.
 * Note that the defaults given are the Canvas defaults, which will be used if
 * option is not defined. The `get` functions return whatever was entered in
 * the options; they will not return the default.
 * @api
 */
var Stroke = /** @class */ (function () {
    /**
     * @param {Options=} opt_options Options.
     */
    function Stroke(opt_options) {
        var options = opt_options || {};
        /**
         * @private
         * @type {import("../color.js").Color|import("../colorlike.js").ColorLike}
         */
        this.color_ = options.color !== undefined ? options.color : null;
        /**
         * @private
         * @type {CanvasLineCap|undefined}
         */
        this.lineCap_ = options.lineCap;
        /**
         * @private
         * @type {Array<number>}
         */
        this.lineDash_ = options.lineDash !== undefined ? options.lineDash : null;
        /**
         * @private
         * @type {number|undefined}
         */
        this.lineDashOffset_ = options.lineDashOffset;
        /**
         * @private
         * @type {CanvasLineJoin|undefined}
         */
        this.lineJoin_ = options.lineJoin;
        /**
         * @private
         * @type {number|undefined}
         */
        this.miterLimit_ = options.miterLimit;
        /**
         * @private
         * @type {number|undefined}
         */
        this.width_ = options.width;
    }
    /**
     * Clones the style.
     * @return {Stroke} The cloned style.
     * @api
     */
    Stroke.prototype.clone = function () {
        var color = this.getColor();
        return new Stroke({
            color: Array.isArray(color) ? color.slice() : color || undefined,
            lineCap: this.getLineCap(),
            lineDash: this.getLineDash() ? this.getLineDash().slice() : undefined,
            lineDashOffset: this.getLineDashOffset(),
            lineJoin: this.getLineJoin(),
            miterLimit: this.getMiterLimit(),
            width: this.getWidth(),
        });
    };
    /**
     * Get the stroke color.
     * @return {import("../color.js").Color|import("../colorlike.js").ColorLike} Color.
     * @api
     */
    Stroke.prototype.getColor = function () {
        return this.color_;
    };
    /**
     * Get the line cap type for the stroke.
     * @return {CanvasLineCap|undefined} Line cap.
     * @api
     */
    Stroke.prototype.getLineCap = function () {
        return this.lineCap_;
    };
    /**
     * Get the line dash style for the stroke.
     * @return {Array<number>} Line dash.
     * @api
     */
    Stroke.prototype.getLineDash = function () {
        return this.lineDash_;
    };
    /**
     * Get the line dash offset for the stroke.
     * @return {number|undefined} Line dash offset.
     * @api
     */
    Stroke.prototype.getLineDashOffset = function () {
        return this.lineDashOffset_;
    };
    /**
     * Get the line join type for the stroke.
     * @return {CanvasLineJoin|undefined} Line join.
     * @api
     */
    Stroke.prototype.getLineJoin = function () {
        return this.lineJoin_;
    };
    /**
     * Get the miter limit for the stroke.
     * @return {number|undefined} Miter limit.
     * @api
     */
    Stroke.prototype.getMiterLimit = function () {
        return this.miterLimit_;
    };
    /**
     * Get the stroke width.
     * @return {number|undefined} Width.
     * @api
     */
    Stroke.prototype.getWidth = function () {
        return this.width_;
    };
    /**
     * Set the color.
     *
     * @param {import("../color.js").Color|import("../colorlike.js").ColorLike} color Color.
     * @api
     */
    Stroke.prototype.setColor = function (color) {
        this.color_ = color;
    };
    /**
     * Set the line cap.
     *
     * @param {CanvasLineCap|undefined} lineCap Line cap.
     * @api
     */
    Stroke.prototype.setLineCap = function (lineCap) {
        this.lineCap_ = lineCap;
    };
    /**
     * Set the line dash.
     *
     * Please note that Internet Explorer 10 and lower [do not support][mdn] the
     * `setLineDash` method on the `CanvasRenderingContext2D` and therefore this
     * property will have no visual effect in these browsers.
     *
     * [mdn]: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash#Browser_compatibility
     *
     * @param {Array<number>} lineDash Line dash.
     * @api
     */
    Stroke.prototype.setLineDash = function (lineDash) {
        this.lineDash_ = lineDash;
    };
    /**
     * Set the line dash offset.
     *
     * @param {number|undefined} lineDashOffset Line dash offset.
     * @api
     */
    Stroke.prototype.setLineDashOffset = function (lineDashOffset) {
        this.lineDashOffset_ = lineDashOffset;
    };
    /**
     * Set the line join.
     *
     * @param {CanvasLineJoin|undefined} lineJoin Line join.
     * @api
     */
    Stroke.prototype.setLineJoin = function (lineJoin) {
        this.lineJoin_ = lineJoin;
    };
    /**
     * Set the miter limit.
     *
     * @param {number|undefined} miterLimit Miter limit.
     * @api
     */
    Stroke.prototype.setMiterLimit = function (miterLimit) {
        this.miterLimit_ = miterLimit;
    };
    /**
     * Set the width.
     *
     * @param {number|undefined} width Width.
     * @api
     */
    Stroke.prototype.setWidth = function (width) {
        this.width_ = width;
    };
    return Stroke;
}());

/**
 * @module ol/style/Image
 */
/**
 * @typedef {Object} Options
 * @property {number} opacity
 * @property {boolean} rotateWithView
 * @property {number} rotation
 * @property {number|import("../size.js").Size} scale
 * @property {Array<number>} displacement
 */
/**
 * @classdesc
 * A base class used for creating subclasses and not instantiated in
 * apps. Base class for {@link module:ol/style/Icon~Icon}, {@link module:ol/style/Circle~CircleStyle} and
 * {@link module:ol/style/RegularShape~RegularShape}.
 * @abstract
 * @api
 */
var ImageStyle = /** @class */ (function () {
    /**
     * @param {Options} options Options.
     */
    function ImageStyle(options) {
        /**
         * @private
         * @type {number}
         */
        this.opacity_ = options.opacity;
        /**
         * @private
         * @type {boolean}
         */
        this.rotateWithView_ = options.rotateWithView;
        /**
         * @private
         * @type {number}
         */
        this.rotation_ = options.rotation;
        /**
         * @private
         * @type {number|import("../size.js").Size}
         */
        this.scale_ = options.scale;
        /**
         * @private
         * @type {import("../size.js").Size}
         */
        this.scaleArray_ = toSize(options.scale);
        /**
         * @private
         * @type {Array<number>}
         */
        this.displacement_ = options.displacement;
    }
    /**
     * Clones the style.
     * @return {ImageStyle} The cloned style.
     * @api
     */
    ImageStyle.prototype.clone = function () {
        var scale = this.getScale();
        return new ImageStyle({
            opacity: this.getOpacity(),
            scale: Array.isArray(scale) ? scale.slice() : scale,
            rotation: this.getRotation(),
            rotateWithView: this.getRotateWithView(),
            displacement: this.getDisplacement().slice(),
        });
    };
    /**
     * Get the symbolizer opacity.
     * @return {number} Opacity.
     * @api
     */
    ImageStyle.prototype.getOpacity = function () {
        return this.opacity_;
    };
    /**
     * Determine whether the symbolizer rotates with the map.
     * @return {boolean} Rotate with map.
     * @api
     */
    ImageStyle.prototype.getRotateWithView = function () {
        return this.rotateWithView_;
    };
    /**
     * Get the symoblizer rotation.
     * @return {number} Rotation.
     * @api
     */
    ImageStyle.prototype.getRotation = function () {
        return this.rotation_;
    };
    /**
     * Get the symbolizer scale.
     * @return {number|import("../size.js").Size} Scale.
     * @api
     */
    ImageStyle.prototype.getScale = function () {
        return this.scale_;
    };
    /**
     * Get the symbolizer scale array.
     * @return {import("../size.js").Size} Scale array.
     */
    ImageStyle.prototype.getScaleArray = function () {
        return this.scaleArray_;
    };
    /**
     * Get the displacement of the shape
     * @return {Array<number>} Shape's center displacement
     * @api
     */
    ImageStyle.prototype.getDisplacement = function () {
        return this.displacement_;
    };
    /**
     * Get the anchor point in pixels. The anchor determines the center point for the
     * symbolizer.
     * @abstract
     * @return {Array<number>} Anchor.
     */
    ImageStyle.prototype.getAnchor = function () {
        return abstract();
    };
    /**
     * Get the image element for the symbolizer.
     * @abstract
     * @param {number} pixelRatio Pixel ratio.
     * @return {HTMLCanvasElement|HTMLVideoElement|HTMLImageElement} Image element.
     */
    ImageStyle.prototype.getImage = function (pixelRatio) {
        return abstract();
    };
    /**
     * @abstract
     * @return {HTMLCanvasElement|HTMLVideoElement|HTMLImageElement} Image element.
     */
    ImageStyle.prototype.getHitDetectionImage = function () {
        return abstract();
    };
    /*
     * Get the image pixel ratio.
     * @param {number} pixelRatio Pixel ratio.
     * */
    ImageStyle.prototype.getPixelRatio = function (pixelRatio) {
        return 1;
    };
    /**
     * @abstract
     * @return {import("../ImageState.js").default} Image state.
     */
    ImageStyle.prototype.getImageState = function () {
        return abstract();
    };
    /**
     * @abstract
     * @return {import("../size.js").Size} Image size.
     */
    ImageStyle.prototype.getImageSize = function () {
        return abstract();
    };
    /**
     * @abstract
     * @return {import("../size.js").Size} Size of the hit-detection image.
     */
    ImageStyle.prototype.getHitDetectionImageSize = function () {
        return abstract();
    };
    /**
     * Get the origin of the symbolizer.
     * @abstract
     * @return {Array<number>} Origin.
     */
    ImageStyle.prototype.getOrigin = function () {
        return abstract();
    };
    /**
     * Get the size of the symbolizer (in pixels).
     * @abstract
     * @return {import("../size.js").Size} Size.
     */
    ImageStyle.prototype.getSize = function () {
        return abstract();
    };
    /**
     * Set the opacity.
     *
     * @param {number} opacity Opacity.
     * @api
     */
    ImageStyle.prototype.setOpacity = function (opacity) {
        this.opacity_ = opacity;
    };
    /**
     * Set whether to rotate the style with the view.
     *
     * @param {boolean} rotateWithView Rotate with map.
     * @api
     */
    ImageStyle.prototype.setRotateWithView = function (rotateWithView) {
        this.rotateWithView_ = rotateWithView;
    };
    /**
     * Set the rotation.
     *
     * @param {number} rotation Rotation.
     * @api
     */
    ImageStyle.prototype.setRotation = function (rotation) {
        this.rotation_ = rotation;
    };
    /**
     * Set the scale.
     *
     * @param {number|import("../size.js").Size} scale Scale.
     * @api
     */
    ImageStyle.prototype.setScale = function (scale) {
        this.scale_ = scale;
        this.scaleArray_ = toSize(scale);
    };
    /**
     * @abstract
     * @param {function(import("../events/Event.js").default): void} listener Listener function.
     */
    ImageStyle.prototype.listenImageChange = function (listener) {
        abstract();
    };
    /**
     * Load not yet loaded URI.
     * @abstract
     */
    ImageStyle.prototype.load = function () {
        abstract();
    };
    /**
     * @abstract
     * @param {function(import("../events/Event.js").default): void} listener Listener function.
     */
    ImageStyle.prototype.unlistenImageChange = function (listener) {
        abstract();
    };
    return ImageStyle;
}());

/**
 * @module ol/colorlike
 */
/**
 * A type accepted by CanvasRenderingContext2D.fillStyle
 * or CanvasRenderingContext2D.strokeStyle.
 * Represents a color, pattern, or gradient. The origin for patterns and
 * gradients as fill style is an increment of 512 css pixels from map coordinate
 * `[0, 0]`. For seamless repeat patterns, width and height of the pattern image
 * must be a factor of two (2, 4, 8, ..., 512).
 *
 * @typedef {string|CanvasPattern|CanvasGradient} ColorLike
 * @api
 */
/**
 * @param {import("./color.js").Color|ColorLike} color Color.
 * @return {ColorLike} The color as an {@link ol/colorlike~ColorLike}.
 * @api
 */
function asColorLike(color) {
    if (Array.isArray(color)) {
        return toString(color);
    }
    else {
        return color;
    }
}

/**
 * @module ol/style/RegularShape
 */
var __extends = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * Specify radius for regular polygons, or radius1 and radius2 for stars.
 * @typedef {Object} Options
 * @property {import("./Fill.js").default} [fill] Fill style.
 * @property {number} points Number of points for stars and regular polygons. In case of a polygon, the number of points
 * is the number of sides.
 * @property {number} [radius] Radius of a regular polygon.
 * @property {number} [radius1] Outer radius of a star.
 * @property {number} [radius2] Inner radius of a star.
 * @property {number} [angle=0] Shape's angle in radians. A value of 0 will have one of the shape's point facing up.
 * @property {Array<number>} [displacement=[0,0]] Displacement of the shape
 * @property {import("./Stroke.js").default} [stroke] Stroke style.
 * @property {number} [rotation=0] Rotation in radians (positive rotation clockwise).
 * @property {boolean} [rotateWithView=false] Whether to rotate the shape with the view.
 * @property {number|import("../size.js").Size} [scale=1] Scale. Unless two dimensional scaling is required a better
 * result may be obtained with appropriate settings for `radius`, `radius1` and `radius2`.
 */
/**
 * @typedef {Object} RenderOptions
 * @property {import("../colorlike.js").ColorLike} [strokeStyle]
 * @property {number} strokeWidth
 * @property {number} size
 * @property {CanvasLineCap} lineCap
 * @property {Array<number>} lineDash
 * @property {number} lineDashOffset
 * @property {CanvasLineJoin} lineJoin
 * @property {number} miterLimit
 */
/**
 * @classdesc
 * Set regular shape style for vector features. The resulting shape will be
 * a regular polygon when `radius` is provided, or a star when `radius1` and
 * `radius2` are provided.
 * @api
 */
var RegularShape = /** @class */ (function (_super) {
    __extends(RegularShape, _super);
    /**
     * @param {Options} options Options.
     */
    function RegularShape(options) {
        var _this = this;
        /**
         * @type {boolean}
         */
        var rotateWithView = options.rotateWithView !== undefined ? options.rotateWithView : false;
        _this = _super.call(this, {
            opacity: 1,
            rotateWithView: rotateWithView,
            rotation: options.rotation !== undefined ? options.rotation : 0,
            scale: options.scale !== undefined ? options.scale : 1,
            displacement: options.displacement !== undefined ? options.displacement : [0, 0],
        }) || this;
        /**
         * @private
         * @type {Object<number, HTMLCanvasElement>}
         */
        _this.canvas_ = {};
        /**
         * @private
         * @type {HTMLCanvasElement}
         */
        _this.hitDetectionCanvas_ = null;
        /**
         * @private
         * @type {import("./Fill.js").default}
         */
        _this.fill_ = options.fill !== undefined ? options.fill : null;
        /**
         * @private
         * @type {Array<number>}
         */
        _this.origin_ = [0, 0];
        /**
         * @private
         * @type {number}
         */
        _this.points_ = options.points;
        /**
         * @protected
         * @type {number}
         */
        _this.radius_ =
            options.radius !== undefined ? options.radius : options.radius1;
        /**
         * @private
         * @type {number|undefined}
         */
        _this.radius2_ = options.radius2;
        /**
         * @private
         * @type {number}
         */
        _this.angle_ = options.angle !== undefined ? options.angle : 0;
        /**
         * @private
         * @type {import("./Stroke.js").default}
         */
        _this.stroke_ = options.stroke !== undefined ? options.stroke : null;
        /**
         * @private
         * @type {Array<number>}
         */
        _this.anchor_ = null;
        /**
         * @private
         * @type {import("../size.js").Size}
         */
        _this.size_ = null;
        /**
         * @private
         * @type {import("../size.js").Size}
         */
        _this.imageSize_ = null;
        /**
         * @private
         * @type {import("../size.js").Size}
         */
        _this.hitDetectionImageSize_ = null;
        _this.render();
        return _this;
    }
    /**
     * Clones the style.
     * @return {RegularShape} The cloned style.
     * @api
     */
    RegularShape.prototype.clone = function () {
        var scale = this.getScale();
        var style = new RegularShape({
            fill: this.getFill() ? this.getFill().clone() : undefined,
            points: this.getPoints(),
            radius: this.getRadius(),
            radius2: this.getRadius2(),
            angle: this.getAngle(),
            stroke: this.getStroke() ? this.getStroke().clone() : undefined,
            rotation: this.getRotation(),
            rotateWithView: this.getRotateWithView(),
            scale: Array.isArray(scale) ? scale.slice() : scale,
            displacement: this.getDisplacement().slice(),
        });
        style.setOpacity(this.getOpacity());
        return style;
    };
    /**
     * Get the anchor point in pixels. The anchor determines the center point for the
     * symbolizer.
     * @return {Array<number>} Anchor.
     * @api
     */
    RegularShape.prototype.getAnchor = function () {
        return this.anchor_;
    };
    /**
     * Get the angle used in generating the shape.
     * @return {number} Shape's rotation in radians.
     * @api
     */
    RegularShape.prototype.getAngle = function () {
        return this.angle_;
    };
    /**
     * Get the fill style for the shape.
     * @return {import("./Fill.js").default} Fill style.
     * @api
     */
    RegularShape.prototype.getFill = function () {
        return this.fill_;
    };
    /**
     * @return {HTMLCanvasElement} Image element.
     */
    RegularShape.prototype.getHitDetectionImage = function () {
        if (!this.hitDetectionCanvas_) {
            var renderOptions = this.createRenderOptions();
            this.createHitDetectionCanvas_(renderOptions);
        }
        return this.hitDetectionCanvas_;
    };
    /**
     * Get the image icon.
     * @param {number} pixelRatio Pixel ratio.
     * @return {HTMLCanvasElement} Image or Canvas element.
     * @api
     */
    RegularShape.prototype.getImage = function (pixelRatio) {
        if (!this.canvas_[pixelRatio || 1]) {
            var renderOptions = this.createRenderOptions();
            var context = createCanvasContext2D(renderOptions.size * pixelRatio || 1, renderOptions.size * pixelRatio || 1);
            this.draw_(renderOptions, context, 0, 0, pixelRatio || 1);
            this.canvas_[pixelRatio || 1] = context.canvas;
        }
        return this.canvas_[pixelRatio || 1];
    };
    /*
     * Get the image pixel ratio.
     * @param {number} pixelRatio Pixel ratio.
     * */
    RegularShape.prototype.getPixelRatio = function (pixelRatio) {
        return pixelRatio;
    };
    /**
     * @return {import("../size.js").Size} Image size.
     */
    RegularShape.prototype.getImageSize = function () {
        return this.imageSize_;
    };
    /**
     * @return {import("../size.js").Size} Size of the hit-detection image.
     */
    RegularShape.prototype.getHitDetectionImageSize = function () {
        return this.hitDetectionImageSize_;
    };
    /**
     * @return {import("../ImageState.js").default} Image state.
     */
    RegularShape.prototype.getImageState = function () {
        return ImageState.LOADED;
    };
    /**
     * Get the origin of the symbolizer.
     * @return {Array<number>} Origin.
     * @api
     */
    RegularShape.prototype.getOrigin = function () {
        return this.origin_;
    };
    /**
     * Get the number of points for generating the shape.
     * @return {number} Number of points for stars and regular polygons.
     * @api
     */
    RegularShape.prototype.getPoints = function () {
        return this.points_;
    };
    /**
     * Get the (primary) radius for the shape.
     * @return {number} Radius.
     * @api
     */
    RegularShape.prototype.getRadius = function () {
        return this.radius_;
    };
    /**
     * Get the secondary radius for the shape.
     * @return {number|undefined} Radius2.
     * @api
     */
    RegularShape.prototype.getRadius2 = function () {
        return this.radius2_;
    };
    /**
     * Get the size of the symbolizer (in pixels).
     * @return {import("../size.js").Size} Size.
     * @api
     */
    RegularShape.prototype.getSize = function () {
        return this.size_;
    };
    /**
     * Get the stroke style for the shape.
     * @return {import("./Stroke.js").default} Stroke style.
     * @api
     */
    RegularShape.prototype.getStroke = function () {
        return this.stroke_;
    };
    /**
     * @param {function(import("../events/Event.js").default): void} listener Listener function.
     */
    RegularShape.prototype.listenImageChange = function (listener) { };
    /**
     * Load not yet loaded URI.
     */
    RegularShape.prototype.load = function () { };
    /**
     * @param {function(import("../events/Event.js").default): void} listener Listener function.
     */
    RegularShape.prototype.unlistenImageChange = function (listener) { };
    /**
     * @returns {RenderOptions}  The render options
     * @protected
     */
    RegularShape.prototype.createRenderOptions = function () {
        var lineCap = defaultLineCap;
        var lineJoin = defaultLineJoin;
        var miterLimit = 0;
        var lineDash = null;
        var lineDashOffset = 0;
        var strokeStyle;
        var strokeWidth = 0;
        if (this.stroke_) {
            strokeStyle = this.stroke_.getColor();
            if (strokeStyle === null) {
                strokeStyle = defaultStrokeStyle;
            }
            strokeStyle = asColorLike(strokeStyle);
            strokeWidth = this.stroke_.getWidth();
            if (strokeWidth === undefined) {
                strokeWidth = defaultLineWidth;
            }
            lineDash = this.stroke_.getLineDash();
            lineDashOffset = this.stroke_.getLineDashOffset();
            lineJoin = this.stroke_.getLineJoin();
            if (lineJoin === undefined) {
                lineJoin = defaultLineJoin;
            }
            lineCap = this.stroke_.getLineCap();
            if (lineCap === undefined) {
                lineCap = defaultLineCap;
            }
            miterLimit = this.stroke_.getMiterLimit();
            if (miterLimit === undefined) {
                miterLimit = defaultMiterLimit;
            }
        }
        var size = 2 * (this.radius_ + strokeWidth) + 1;
        return {
            strokeStyle: strokeStyle,
            strokeWidth: strokeWidth,
            size: size,
            lineCap: lineCap,
            lineDash: lineDash,
            lineDashOffset: lineDashOffset,
            lineJoin: lineJoin,
            miterLimit: miterLimit,
        };
    };
    /**
     * @protected
     */
    RegularShape.prototype.render = function () {
        var renderOptions = this.createRenderOptions();
        var context = createCanvasContext2D(renderOptions.size, renderOptions.size);
        this.draw_(renderOptions, context, 0, 0, 1);
        this.canvas_ = {};
        this.canvas_[1] = context.canvas;
        // canvas.width and height are rounded to the closest integer
        var size = context.canvas.width;
        var imageSize = size;
        var displacement = this.getDisplacement();
        this.hitDetectionImageSize_ = [renderOptions.size, renderOptions.size];
        this.createHitDetectionCanvas_(renderOptions);
        this.anchor_ = [size / 2 - displacement[0], size / 2 + displacement[1]];
        this.size_ = [size, size];
        this.imageSize_ = [imageSize, imageSize];
    };
    /**
     * @private
     * @param {RenderOptions} renderOptions Render options.
     * @param {CanvasRenderingContext2D} context The rendering context.
     * @param {number} x The origin for the symbol (x).
     * @param {number} y The origin for the symbol (y).
     * @param {number} pixelRatio The pixel ratio.
     */
    RegularShape.prototype.draw_ = function (renderOptions, context, x, y, pixelRatio) {
        var i, angle0, radiusC;
        // reset transform
        context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        // then move to (x, y)
        context.translate(x, y);
        context.beginPath();
        var points = this.points_;
        if (points === Infinity) {
            context.arc(renderOptions.size / 2, renderOptions.size / 2, this.radius_, 0, 2 * Math.PI, true);
        }
        else {
            var radius2 = this.radius2_ !== undefined ? this.radius2_ : this.radius_;
            if (radius2 !== this.radius_) {
                points = 2 * points;
            }
            for (i = 0; i <= points; i++) {
                angle0 = (i * 2 * Math.PI) / points - Math.PI / 2 + this.angle_;
                radiusC = i % 2 === 0 ? this.radius_ : radius2;
                context.lineTo(renderOptions.size / 2 + radiusC * Math.cos(angle0), renderOptions.size / 2 + radiusC * Math.sin(angle0));
            }
        }
        if (this.fill_) {
            var color = this.fill_.getColor();
            if (color === null) {
                color = defaultFillStyle;
            }
            context.fillStyle = asColorLike(color);
            context.fill();
        }
        if (this.stroke_) {
            context.strokeStyle = renderOptions.strokeStyle;
            context.lineWidth = renderOptions.strokeWidth;
            if (context.setLineDash && renderOptions.lineDash) {
                context.setLineDash(renderOptions.lineDash);
                context.lineDashOffset = renderOptions.lineDashOffset;
            }
            context.lineCap = renderOptions.lineCap;
            context.lineJoin = renderOptions.lineJoin;
            context.miterLimit = renderOptions.miterLimit;
            context.stroke();
        }
        context.closePath();
    };
    /**
     * @private
     * @param {RenderOptions} renderOptions Render options.
     */
    RegularShape.prototype.createHitDetectionCanvas_ = function (renderOptions) {
        this.hitDetectionCanvas_ = this.getImage(1);
        if (this.fill_) {
            var color = this.fill_.getColor();
            // determine if fill is transparent (or pattern or gradient)
            var opacity = 0;
            if (typeof color === 'string') {
                color = asArray(color);
            }
            if (color === null) {
                opacity = 1;
            }
            else if (Array.isArray(color)) {
                opacity = color.length === 4 ? color[3] : 1;
            }
            if (opacity === 0) {
                // if a transparent fill style is set, create an extra hit-detection image
                // with a default fill style
                var context = createCanvasContext2D(renderOptions.size, renderOptions.size);
                this.hitDetectionCanvas_ = context.canvas;
                this.drawHitDetectionCanvas_(renderOptions, context, 0, 0);
            }
        }
    };
    /**
     * @private
     * @param {RenderOptions} renderOptions Render options.
     * @param {CanvasRenderingContext2D} context The context.
     * @param {number} x The origin for the symbol (x).
     * @param {number} y The origin for the symbol (y).
     */
    RegularShape.prototype.drawHitDetectionCanvas_ = function (renderOptions, context, x, y) {
        // move to (x, y)
        context.translate(x, y);
        context.beginPath();
        var points = this.points_;
        if (points === Infinity) {
            context.arc(renderOptions.size / 2, renderOptions.size / 2, this.radius_, 0, 2 * Math.PI, true);
        }
        else {
            var radius2 = this.radius2_ !== undefined ? this.radius2_ : this.radius_;
            if (radius2 !== this.radius_) {
                points = 2 * points;
            }
            var i = void 0, radiusC = void 0, angle0 = void 0;
            for (i = 0; i <= points; i++) {
                angle0 = (i * 2 * Math.PI) / points - Math.PI / 2 + this.angle_;
                radiusC = i % 2 === 0 ? this.radius_ : radius2;
                context.lineTo(renderOptions.size / 2 + radiusC * Math.cos(angle0), renderOptions.size / 2 + radiusC * Math.sin(angle0));
            }
        }
        context.fillStyle = defaultFillStyle;
        context.fill();
        if (this.stroke_) {
            context.strokeStyle = renderOptions.strokeStyle;
            context.lineWidth = renderOptions.strokeWidth;
            if (renderOptions.lineDash) {
                context.setLineDash(renderOptions.lineDash);
                context.lineDashOffset = renderOptions.lineDashOffset;
            }
            context.stroke();
        }
        context.closePath();
    };
    return RegularShape;
}(ImageStyle));

/**
 * @module ol/style/Circle
 */
var __extends$1 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @typedef {Object} Options
 * @property {import("./Fill.js").default} [fill] Fill style.
 * @property {number} radius Circle radius.
 * @property {import("./Stroke.js").default} [stroke] Stroke style.
 * @property {Array<number>} [displacement=[0,0]] displacement
 * @property {number|import("../size.js").Size} [scale=1] Scale. A two dimensional scale will produce an ellipse.
 * Unless two dimensional scaling is required a better result may be obtained with an appropriate setting for `radius`.
 * @property {number} [rotation=0] Rotation in radians
 * (positive rotation clockwise, meaningful only when used in conjunction with a two dimensional scale).
 * @property {boolean} [rotateWithView=false] Whether to rotate the shape with the view
 * (meaningful only when used in conjunction with a two dimensional scale).
 */
/**
 * @classdesc
 * Set circle style for vector features.
 * @api
 */
var CircleStyle = /** @class */ (function (_super) {
    __extends$1(CircleStyle, _super);
    /**
     * @param {Options=} opt_options Options.
     */
    function CircleStyle(opt_options) {
        var _this = this;
        var options = opt_options ? opt_options : {};
        _this = _super.call(this, {
            points: Infinity,
            fill: options.fill,
            radius: options.radius,
            stroke: options.stroke,
            scale: options.scale !== undefined ? options.scale : 1,
            rotation: options.rotation !== undefined ? options.rotation : 0,
            rotateWithView: options.rotateWithView !== undefined ? options.rotateWithView : false,
            displacement: options.displacement !== undefined ? options.displacement : [0, 0],
        }) || this;
        return _this;
    }
    /**
     * Clones the style.
     * @return {CircleStyle} The cloned style.
     * @api
     */
    CircleStyle.prototype.clone = function () {
        var scale = this.getScale();
        var style = new CircleStyle({
            fill: this.getFill() ? this.getFill().clone() : undefined,
            stroke: this.getStroke() ? this.getStroke().clone() : undefined,
            radius: this.getRadius(),
            scale: Array.isArray(scale) ? scale.slice() : scale,
            rotation: this.getRotation(),
            rotateWithView: this.getRotateWithView(),
            displacement: this.getDisplacement().slice(),
        });
        style.setOpacity(this.getOpacity());
        return style;
    };
    /**
     * Set the circle radius.
     *
     * @param {number} radius Circle radius.
     * @api
     */
    CircleStyle.prototype.setRadius = function (radius) {
        this.radius_ = radius;
        this.render();
    };
    return CircleStyle;
}(RegularShape));

/**
 * @module ol/style/Style
 */
/**
 * A function that takes an {@link module:ol/Feature} and a `{number}`
 * representing the view's resolution. The function should return a
 * {@link module:ol/style/Style} or an array of them. This way e.g. a
 * vector layer can be styled. If the function returns `undefined`, the
 * feature will not be rendered.
 *
 * @typedef {function(import("../Feature.js").FeatureLike, number):(Style|Array<Style>|void)} StyleFunction
 */
/**
 * A {@link Style}, an array of {@link Style}, or a {@link StyleFunction}.
 * @typedef {Style|Array<Style>|StyleFunction} StyleLike
 */
/**
 * A function that takes an {@link module:ol/Feature} as argument and returns an
 * {@link module:ol/geom/Geometry} that will be rendered and styled for the feature.
 *
 * @typedef {function(import("../Feature.js").FeatureLike):
 *     (import("../geom/Geometry.js").default|import("../render/Feature.js").default|undefined)} GeometryFunction
 */
/**
 * Custom renderer function. Takes two arguments:
 *
 * 1. The pixel coordinates of the geometry in GeoJSON notation.
 * 2. The {@link module:ol/render~State} of the layer renderer.
 *
 * @typedef {function((import("../coordinate.js").Coordinate|Array<import("../coordinate.js").Coordinate>|Array<Array<import("../coordinate.js").Coordinate>>),import("../render.js").State): void}
 * RenderFunction
 */
/**
 * @typedef {Object} Options
 * @property {string|import("../geom/Geometry.js").default|GeometryFunction} [geometry] Feature property or geometry
 * or function returning a geometry to render for this style.
 * @property {import("./Fill.js").default} [fill] Fill style.
 * @property {import("./Image.js").default} [image] Image style.
 * @property {RenderFunction} [renderer] Custom renderer. When configured, `fill`, `stroke` and `image` will be
 * ignored, and the provided function will be called with each render frame for each geometry.
 * @property {import("./Stroke.js").default} [stroke] Stroke style.
 * @property {import("./Text.js").default} [text] Text style.
 * @property {number} [zIndex] Z index.
 */
/**
 * @classdesc
 * Container for vector feature rendering styles. Any changes made to the style
 * or its children through `set*()` methods will not take effect until the
 * feature or layer that uses the style is re-rendered.
 *
 * ## Feature styles
 *
 * If no style is defined, the following default style is used:
 * ```js
 *  import {Fill, Stroke, Circle, Style} from 'ol/style';
 *
 *  var fill = new Fill({
 *    color: 'rgba(255,255,255,0.4)'
 *  });
 *  var stroke = new Stroke({
 *    color: '#3399CC',
 *    width: 1.25
 *  });
 *  var styles = [
 *    new Style({
 *      image: new Circle({
 *        fill: fill,
 *        stroke: stroke,
 *        radius: 5
 *      }),
 *      fill: fill,
 *      stroke: stroke
 *    })
 *  ];
 * ```
 *
 * A separate editing style has the following defaults:
 * ```js
 *  import {Fill, Stroke, Circle, Style} from 'ol/style';
 *  import GeometryType from 'ol/geom/GeometryType';
 *
 *  var white = [255, 255, 255, 1];
 *  var blue = [0, 153, 255, 1];
 *  var width = 3;
 *  styles[GeometryType.POLYGON] = [
 *    new Style({
 *      fill: new Fill({
 *        color: [255, 255, 255, 0.5]
 *      })
 *    })
 *  ];
 *  styles[GeometryType.MULTI_POLYGON] =
 *      styles[GeometryType.POLYGON];
 *  styles[GeometryType.LINE_STRING] = [
 *    new Style({
 *      stroke: new Stroke({
 *        color: white,
 *        width: width + 2
 *      })
 *    }),
 *    new Style({
 *      stroke: new Stroke({
 *        color: blue,
 *        width: width
 *      })
 *    })
 *  ];
 *  styles[GeometryType.MULTI_LINE_STRING] =
 *      styles[GeometryType.LINE_STRING];
 *  styles[GeometryType.POINT] = [
 *    new Style({
 *      image: new Circle({
 *        radius: width * 2,
 *        fill: new Fill({
 *          color: blue
 *        }),
 *        stroke: new Stroke({
 *          color: white,
 *          width: width / 2
 *        })
 *      }),
 *      zIndex: Infinity
 *    })
 *  ];
 *  styles[GeometryType.MULTI_POINT] =
 *      styles[GeometryType.POINT];
 *  styles[GeometryType.GEOMETRY_COLLECTION] =
 *      styles[GeometryType.POLYGON].concat(
 *          styles[GeometryType.LINE_STRING],
 *          styles[GeometryType.POINT]
 *      );
 * ```
 *
 * @api
 */
var Style = /** @class */ (function () {
    /**
     * @param {Options=} opt_options Style options.
     */
    function Style(opt_options) {
        var options = opt_options || {};
        /**
         * @private
         * @type {string|import("../geom/Geometry.js").default|GeometryFunction}
         */
        this.geometry_ = null;
        /**
         * @private
         * @type {!GeometryFunction}
         */
        this.geometryFunction_ = defaultGeometryFunction;
        if (options.geometry !== undefined) {
            this.setGeometry(options.geometry);
        }
        /**
         * @private
         * @type {import("./Fill.js").default}
         */
        this.fill_ = options.fill !== undefined ? options.fill : null;
        /**
         * @private
         * @type {import("./Image.js").default}
         */
        this.image_ = options.image !== undefined ? options.image : null;
        /**
         * @private
         * @type {RenderFunction|null}
         */
        this.renderer_ = options.renderer !== undefined ? options.renderer : null;
        /**
         * @private
         * @type {import("./Stroke.js").default}
         */
        this.stroke_ = options.stroke !== undefined ? options.stroke : null;
        /**
         * @private
         * @type {import("./Text.js").default}
         */
        this.text_ = options.text !== undefined ? options.text : null;
        /**
         * @private
         * @type {number|undefined}
         */
        this.zIndex_ = options.zIndex;
    }
    /**
     * Clones the style.
     * @return {Style} The cloned style.
     * @api
     */
    Style.prototype.clone = function () {
        var geometry = this.getGeometry();
        if (geometry && typeof geometry === 'object') {
            geometry = /** @type {import("../geom/Geometry.js").default} */ (geometry).clone();
        }
        return new Style({
            geometry: geometry,
            fill: this.getFill() ? this.getFill().clone() : undefined,
            image: this.getImage() ? this.getImage().clone() : undefined,
            stroke: this.getStroke() ? this.getStroke().clone() : undefined,
            text: this.getText() ? this.getText().clone() : undefined,
            zIndex: this.getZIndex(),
        });
    };
    /**
     * Get the custom renderer function that was configured with
     * {@link #setRenderer} or the `renderer` constructor option.
     * @return {RenderFunction|null} Custom renderer function.
     * @api
     */
    Style.prototype.getRenderer = function () {
        return this.renderer_;
    };
    /**
     * Sets a custom renderer function for this style. When set, `fill`, `stroke`
     * and `image` options of the style will be ignored.
     * @param {RenderFunction|null} renderer Custom renderer function.
     * @api
     */
    Style.prototype.setRenderer = function (renderer) {
        this.renderer_ = renderer;
    };
    /**
     * Get the geometry to be rendered.
     * @return {string|import("../geom/Geometry.js").default|GeometryFunction}
     * Feature property or geometry or function that returns the geometry that will
     * be rendered with this style.
     * @api
     */
    Style.prototype.getGeometry = function () {
        return this.geometry_;
    };
    /**
     * Get the function used to generate a geometry for rendering.
     * @return {!GeometryFunction} Function that is called with a feature
     * and returns the geometry to render instead of the feature's geometry.
     * @api
     */
    Style.prototype.getGeometryFunction = function () {
        return this.geometryFunction_;
    };
    /**
     * Get the fill style.
     * @return {import("./Fill.js").default} Fill style.
     * @api
     */
    Style.prototype.getFill = function () {
        return this.fill_;
    };
    /**
     * Set the fill style.
     * @param {import("./Fill.js").default} fill Fill style.
     * @api
     */
    Style.prototype.setFill = function (fill) {
        this.fill_ = fill;
    };
    /**
     * Get the image style.
     * @return {import("./Image.js").default} Image style.
     * @api
     */
    Style.prototype.getImage = function () {
        return this.image_;
    };
    /**
     * Set the image style.
     * @param {import("./Image.js").default} image Image style.
     * @api
     */
    Style.prototype.setImage = function (image) {
        this.image_ = image;
    };
    /**
     * Get the stroke style.
     * @return {import("./Stroke.js").default} Stroke style.
     * @api
     */
    Style.prototype.getStroke = function () {
        return this.stroke_;
    };
    /**
     * Set the stroke style.
     * @param {import("./Stroke.js").default} stroke Stroke style.
     * @api
     */
    Style.prototype.setStroke = function (stroke) {
        this.stroke_ = stroke;
    };
    /**
     * Get the text style.
     * @return {import("./Text.js").default} Text style.
     * @api
     */
    Style.prototype.getText = function () {
        return this.text_;
    };
    /**
     * Set the text style.
     * @param {import("./Text.js").default} text Text style.
     * @api
     */
    Style.prototype.setText = function (text) {
        this.text_ = text;
    };
    /**
     * Get the z-index for the style.
     * @return {number|undefined} ZIndex.
     * @api
     */
    Style.prototype.getZIndex = function () {
        return this.zIndex_;
    };
    /**
     * Set a geometry that is rendered instead of the feature's geometry.
     *
     * @param {string|import("../geom/Geometry.js").default|GeometryFunction} geometry
     *     Feature property or geometry or function returning a geometry to render
     *     for this style.
     * @api
     */
    Style.prototype.setGeometry = function (geometry) {
        if (typeof geometry === 'function') {
            this.geometryFunction_ = geometry;
        }
        else if (typeof geometry === 'string') {
            this.geometryFunction_ = function (feature) {
                return /** @type {import("../geom/Geometry.js").default} */ (feature.get(geometry));
            };
        }
        else if (!geometry) {
            this.geometryFunction_ = defaultGeometryFunction;
        }
        else if (geometry !== undefined) {
            this.geometryFunction_ = function () {
                return /** @type {import("../geom/Geometry.js").default} */ (geometry);
            };
        }
        this.geometry_ = geometry;
    };
    /**
     * Set the z-index.
     *
     * @param {number|undefined} zIndex ZIndex.
     * @api
     */
    Style.prototype.setZIndex = function (zIndex) {
        this.zIndex_ = zIndex;
    };
    return Style;
}());
/**
 * Convert the provided object into a style function.  Functions passed through
 * unchanged.  Arrays of Style or single style objects wrapped in a
 * new style function.
 * @param {StyleFunction|Array<Style>|Style} obj
 *     A style function, a single style, or an array of styles.
 * @return {StyleFunction} A style function.
 */
function toFunction(obj) {
    var styleFunction;
    if (typeof obj === 'function') {
        styleFunction = obj;
    }
    else {
        /**
         * @type {Array<Style>}
         */
        var styles_1;
        if (Array.isArray(obj)) {
            styles_1 = obj;
        }
        else {
            assert(typeof ( /** @type {?} */(obj).getZIndex) === 'function', 41); // Expected an `Style` or an array of `Style`
            var style = /** @type {Style} */ (obj);
            styles_1 = [style];
        }
        styleFunction = function () {
            return styles_1;
        };
    }
    return styleFunction;
}
/**
 * @type {Array<Style>}
 */
var defaultStyles = null;
/**
 * @param {import("../Feature.js").FeatureLike} feature Feature.
 * @param {number} resolution Resolution.
 * @return {Array<Style>} Style.
 */
function createDefaultStyle(feature, resolution) {
    // We don't use an immediately-invoked function
    // and a closure so we don't get an error at script evaluation time in
    // browsers that do not support Canvas. (import("./Circle.js").CircleStyle does
    // canvas.getContext('2d') at construction time, which will cause an.error
    // in such browsers.)
    if (!defaultStyles) {
        var fill = new Fill({
            color: 'rgba(255,255,255,0.4)',
        });
        var stroke = new Stroke({
            color: '#3399CC',
            width: 1.25,
        });
        defaultStyles = [
            new Style({
                image: new CircleStyle({
                    fill: fill,
                    stroke: stroke,
                    radius: 5,
                }),
                fill: fill,
                stroke: stroke,
            }),
        ];
    }
    return defaultStyles;
}
/**
 * Function that is called with a feature and returns its default geometry.
 * @param {import("../Feature.js").FeatureLike} feature Feature to get the geometry for.
 * @return {import("../geom/Geometry.js").default|import("../render/Feature.js").default|undefined} Geometry to render.
 */
function defaultGeometryFunction(feature) {
    return feature.getGeometry();
}

export { CircleStyle as C, Fill as F, ImageStyle as I, Style as S, asColorLike as a, Stroke as b, createDefaultStyle as c, toFunction as t };
