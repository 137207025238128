/**
 * @module ol/ImageState
 */
/**
 * @enum {number}
 */
var ImageState = {
    IDLE: 0,
    LOADING: 1,
    LOADED: 2,
    ERROR: 3,
    EMPTY: 4,
};

export { ImageState as I };
