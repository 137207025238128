import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import './EventReport.svelte.css';
/* src/EventReport.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_store_value,
	set_style,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import EventDataTable from "./EventDataTable.svelte.js";

import {
	reportType,
	flights,
	targetNumberBelowThreshold,
	picPercentageBelowThreshold
} from "./TargetDataStore.js";

import EventMap from "./EventMap.svelte.js";
import EventChart from "./EventChart.svelte.js";
import { BlobServiceClient } from "../_snowpack/pkg/@azure/storage-blob.js";

import {
	assignFlightIndex,
	blobToString,
	deserializeReportDataRules
} from "./Utils.js";

import EventReportHeader from "./EventReportHeader.svelte.js";
import PolygonTable from "./PolygonTable.svelte.js";

function create_if_block(ctx) {
	let div;
	let eventmap;
	let t0;
	let t1;
	let current;

	eventmap = new EventMap({
			props: { polygonString: /*polygonString*/ ctx[2] }
		});

	let if_block0 = /*$reportType*/ ctx[4] === "flight" && create_if_block_2(ctx);
	let if_block1 = /*$reportType*/ ctx[4] === "polygon" && create_if_block_1(ctx);

	return {
		c() {
			div = element("div");
			create_component(eventmap.$$.fragment);
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			attr(div, "class", "content svelte-g7ty15");
			set_style(div, "top", /*height*/ ctx[3] + "px");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(eventmap, div, null);
			append(div, t0);
			if (if_block0) if_block0.m(div, null);
			append(div, t1);
			if (if_block1) if_block1.m(div, null);
			current = true;
		},
		p(ctx, dirty) {
			const eventmap_changes = {};
			if (dirty & /*polygonString*/ 4) eventmap_changes.polygonString = /*polygonString*/ ctx[2];
			eventmap.$set(eventmap_changes);

			if (/*$reportType*/ ctx[4] === "flight") {
				if (if_block0) {
					if (dirty & /*$reportType*/ 16) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*$reportType*/ ctx[4] === "polygon") {
				if (if_block1) {
					if (dirty & /*$reportType*/ 16) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty & /*height*/ 8) {
				set_style(div, "top", /*height*/ ctx[3] + "px");
			}
		},
		i(local) {
			if (current) return;
			transition_in(eventmap.$$.fragment, local);
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(eventmap.$$.fragment, local);
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(eventmap);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

// (65:9) {#if ($reportType === 'flight')}
function create_if_block_2(ctx) {
	let eventdatatable;
	let t0;
	let div0;
	let eventchart0;
	let t1;
	let div1;
	let eventchart1;
	let current;
	eventdatatable = new EventDataTable({});

	eventchart0 = new EventChart({
			props: { selection: "alt", label: "Altitude [ft]" }
		});

	eventchart1 = new EventChart({
			props: {
				selection: "groundSpeed",
				label: "Ground Speed [ft/s]"
			}
		});

	return {
		c() {
			create_component(eventdatatable.$$.fragment);
			t0 = space();
			div0 = element("div");
			create_component(eventchart0.$$.fragment);
			t1 = space();
			div1 = element("div");
			create_component(eventchart1.$$.fragment);
			attr(div0, "class", "chart svelte-g7ty15");
			attr(div1, "class", "chart svelte-g7ty15");
		},
		m(target, anchor) {
			mount_component(eventdatatable, target, anchor);
			insert(target, t0, anchor);
			insert(target, div0, anchor);
			mount_component(eventchart0, div0, null);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			mount_component(eventchart1, div1, null);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(eventdatatable.$$.fragment, local);
			transition_in(eventchart0.$$.fragment, local);
			transition_in(eventchart1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(eventdatatable.$$.fragment, local);
			transition_out(eventchart0.$$.fragment, local);
			transition_out(eventchart1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(eventdatatable, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div0);
			destroy_component(eventchart0);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			destroy_component(eventchart1);
		}
	};
}

// (70:9) {#if ($reportType === 'polygon')}
function create_if_block_1(ctx) {
	let div;
	let polygontable;
	let current;
	polygontable = new PolygonTable({});

	return {
		c() {
			div = element("div");
			create_component(polygontable.$$.fragment);
			set_style(div, "padding", "24px");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(polygontable, div, null);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(polygontable.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(polygontable.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(polygontable);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let eventreportheader;
	let updating_height;
	let t;
	let current;

	function eventreportheader_height_binding(value) {
		/*eventreportheader_height_binding*/ ctx[10](value);
	}

	let eventreportheader_props = {
		title: /*title*/ ctx[0],
		subtitle: /*subtitle*/ ctx[1]
	};

	if (/*height*/ ctx[3] !== void 0) {
		eventreportheader_props.height = /*height*/ ctx[3];
	}

	eventreportheader = new EventReportHeader({ props: eventreportheader_props });
	binding_callbacks.push(() => bind(eventreportheader, "height", eventreportheader_height_binding));
	let if_block = /*height*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			create_component(eventreportheader.$$.fragment);
			t = space();
			if (if_block) if_block.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(eventreportheader, div, null);
			append(div, t);
			if (if_block) if_block.m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const eventreportheader_changes = {};
			if (dirty & /*title*/ 1) eventreportheader_changes.title = /*title*/ ctx[0];
			if (dirty & /*subtitle*/ 2) eventreportheader_changes.subtitle = /*subtitle*/ ctx[1];

			if (!updating_height && dirty & /*height*/ 8) {
				updating_height = true;
				eventreportheader_changes.height = /*height*/ ctx[3];
				add_flush_callback(() => updating_height = false);
			}

			eventreportheader.$set(eventreportheader_changes);

			if (/*height*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*height*/ 8) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(eventreportheader.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(eventreportheader.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(eventreportheader);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let ymd;
	let blobDestination;
	let $reportType;
	let $targetNumberBelowThreshold;
	let $picPercentageBelowThreshold;
	component_subscribe($$self, reportType, $$value => $$invalidate(4, $reportType = $$value));
	component_subscribe($$self, targetNumberBelowThreshold, $$value => $$invalidate(13, $targetNumberBelowThreshold = $$value));
	component_subscribe($$self, picPercentageBelowThreshold, $$value => $$invalidate(14, $picPercentageBelowThreshold = $$value));
	let { year = "" } = $$props;
	let { month = "" } = $$props;
	let { day = "" } = $$props;
	let { file = "" } = $$props;
	let { sas = "" } = $$props;
	const { SNOWPACK_PUBLIC_AZURE_STORAGE_ACCOUNT, SNOWPACK_PUBLIC_AZURE_REPORT_CONTAINER } = __SNOWPACK_ENV__;
	const connString = `https://${SNOWPACK_PUBLIC_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${sas}`;
	const blobServiceClient = new BlobServiceClient(connString);
	let title = "";
	let subtitle = "";
	let polygonString = "";

	async function runRequest() {
		const containerClient = blobServiceClient.getContainerClient(SNOWPACK_PUBLIC_AZURE_REPORT_CONTAINER);
		const blobClient = containerClient.getBlobClient(blobDestination);
		const downloadBlockBlobResponse = await blobClient.download();
		const json = await blobToString(await downloadBlockBlobResponse.blobBody);
		const parsedJson = JSON.parse(json, deserializeReportDataRules);
		set_store_value(reportType, $reportType = parsedJson.type, $reportType);
		parsedJson.volume.flights = assignFlightIndex(parsedJson.volume.flights);
		flights.set(parsedJson.volume.flights);

		if (parsedJson.type === "flight") {
			let acid = parsedJson.volume?.flights[0]?.history.find(e => e.acid !== "" && e.acid !== null)?.acid;
			$$invalidate(0, title = `Target Address: ${parsedJson.volume?.flights[0]?.icao ?? "Unknown"}`);
			$$invalidate(1, subtitle = `Aircraft ID: ${acid ?? "unknown"}`.toUpperCase());
		} else if (parsedJson.type === "polygon") {
			$$invalidate(0, title = `Volume: ${parsedJson.volume?.name ?? "Unknown"}`);
			$$invalidate(1, subtitle = `Address: ${parsedJson.volume.sac}-${parsedJson.volume.sic}`.toUpperCase());
			$$invalidate(2, polygonString = parsedJson.volume.polygon);
			set_store_value(targetNumberBelowThreshold, $targetNumberBelowThreshold = parsedJson.volume.targetNumberBelowThreshold, $targetNumberBelowThreshold);
			set_store_value(picPercentageBelowThreshold, $picPercentageBelowThreshold = parsedJson.volume.picPercentageBelowThreshold, $picPercentageBelowThreshold);
		}
	}

	let height;

	function eventreportheader_height_binding(value) {
		height = value;
		$$invalidate(3, height);
	}

	$$self.$$set = $$props => {
		if ("year" in $$props) $$invalidate(5, year = $$props.year);
		if ("month" in $$props) $$invalidate(6, month = $$props.month);
		if ("day" in $$props) $$invalidate(7, day = $$props.day);
		if ("file" in $$props) $$invalidate(8, file = $$props.file);
		if ("sas" in $$props) $$invalidate(9, sas = $$props.sas);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*year, month, day*/ 224) {
			$: ymd = `${year}${month}${day}`;
		}

		if ($$self.$$.dirty & /*year, month, day, file*/ 480) {
			$: blobDestination = `${year}/${month}/${day}/${file}`;
		}

		if ($$self.$$.dirty & /*year, month, day, file*/ 480) {
			$: if (year !== "" && month !== "" && day !== "" && file !== "") {
				runRequest();
			}
		}
	};

	return [
		title,
		subtitle,
		polygonString,
		height,
		$reportType,
		year,
		month,
		day,
		file,
		sas,
		eventreportheader_height_binding
	];
}

class EventReport extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			year: 5,
			month: 6,
			day: 7,
			file: 8,
			sas: 9
		});
	}
}

export default EventReport;