/**
 * @module ol/format/FormatType
 */
/**
 * @enum {string}
 */
var FormatType = {
    ARRAY_BUFFER: 'arraybuffer',
    JSON: 'json',
    TEXT: 'text',
    XML: 'xml',
};

export { FormatType as F };
