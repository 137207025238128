import './EventChart.svelte.css';
/* src/EventChart.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	add_render_callback,
	add_resize_listener,
	append,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	create_in_transition,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	set_style,
	space,
	svg_element,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import * as d3 from "../_snowpack/pkg/d3.js";
import { selected, flipTooltip, flights } from "./TargetDataStore.js";
import Axis from "./Axis.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	child_ctx[26] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	child_ctx[26] = i;
	return child_ctx;
}

// (81:3) {#if width}
function create_if_block(ctx) {
	let svg;
	let axis0;
	let axis1;
	let g;
	let path_1;
	let path_1_d_value;
	let each0_anchor;
	let text0;
	let t0;
	let text0_transform_value;
	let text0_x_value;
	let text0_y_value;
	let text1;
	let t1;
	let text1_transform_value;
	let t2;
	let if_block1_anchor;
	let current;

	axis0 = new Axis({
			props: {
				color: /*textColor*/ ctx[0],
				width: /*width*/ ctx[3],
				height: /*height*/ ctx[15],
				margin: /*margin*/ ctx[14].bottom,
				scale: /*xScale*/ ctx[5],
				position: "bottom"
			}
		});

	axis1 = new Axis({
			props: {
				color: /*textColor*/ ctx[0],
				width: /*width*/ ctx[3],
				height: /*height*/ ctx[15],
				margin: /*margin*/ ctx[14].left,
				scale: /*yScale*/ ctx[6],
				position: "left"
			}
		});

	let each_value_1 = /*history*/ ctx[4];
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*events*/ ctx[9];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block0 = !/*selectedIsEmpty*/ ctx[11] && create_if_block_2(ctx);
	let if_block1 = !/*selectedIsEmpty*/ ctx[11] && create_if_block_1(ctx);

	return {
		c() {
			svg = svg_element("svg");
			create_component(axis0.$$.fragment);
			create_component(axis1.$$.fragment);
			g = svg_element("g");
			path_1 = svg_element("path");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			each0_anchor = empty();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			text0 = svg_element("text");
			t0 = text(/*label*/ ctx[2]);
			text1 = svg_element("text");
			t1 = text("Date\n         ");
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
			attr(path_1, "class", "data-path svelte-1bfngop");
			attr(path_1, "d", path_1_d_value = /*path*/ ctx[13](/*history*/ ctx[4]));
			attr(text0, "class", "chart-label");
			attr(text0, "transform", text0_transform_value = "rotate(-90)");
			attr(text0, "x", text0_x_value = -(/*height*/ ctx[15] / 2));
			attr(text0, "y", text0_y_value = 0);
			attr(text0, "text-anchor", "middle");
			attr(text1, "class", "chart-label");
			attr(text1, "transform", text1_transform_value = "translate(" + /*width*/ ctx[3] / 2 + " ," + (/*height*/ ctx[15] + /*margin*/ ctx[14].top) + ")");
			attr(text1, "text-anchor", "middle");
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[15]);
			attr(svg, "class", "svelte-1bfngop");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			mount_component(axis0, svg, null);
			mount_component(axis1, svg, null);
			append(svg, g);
			append(g, path_1);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(svg, null);
			}

			append(svg, each0_anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(svg, null);
			}

			append(svg, text0);
			append(text0, t0);
			append(svg, text1);
			append(text1, t1);
			if (if_block0) if_block0.m(svg, null);
			insert(target, t2, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const axis0_changes = {};
			if (dirty & /*textColor*/ 1) axis0_changes.color = /*textColor*/ ctx[0];
			if (dirty & /*width*/ 8) axis0_changes.width = /*width*/ ctx[3];
			if (dirty & /*xScale*/ 32) axis0_changes.scale = /*xScale*/ ctx[5];
			axis0.$set(axis0_changes);
			const axis1_changes = {};
			if (dirty & /*textColor*/ 1) axis1_changes.color = /*textColor*/ ctx[0];
			if (dirty & /*width*/ 8) axis1_changes.width = /*width*/ ctx[3];
			if (dirty & /*yScale*/ 64) axis1_changes.scale = /*yScale*/ ctx[6];
			axis1.$set(axis1_changes);

			if (!current || dirty & /*path, history*/ 8208 && path_1_d_value !== (path_1_d_value = /*path*/ ctx[13](/*history*/ ctx[4]))) {
				attr(path_1, "d", path_1_d_value);
			}

			if (dirty & /*xScale, history, adjustYScale, selection*/ 1074) {
				each_value_1 = /*history*/ ctx[4];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
						transition_in(each_blocks_1[i], 1);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						transition_in(each_blocks_1[i], 1);
						each_blocks_1[i].m(svg, each0_anchor);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*xScale, events, adjustYScale, selection*/ 1570) {
				each_value = /*events*/ ctx[9];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(svg, text0);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (!current || dirty & /*label*/ 4) set_data(t0, /*label*/ ctx[2]);

			if (!current || dirty & /*width*/ 8 && text1_transform_value !== (text1_transform_value = "translate(" + /*width*/ ctx[3] / 2 + " ," + (/*height*/ ctx[15] + /*margin*/ ctx[14].top) + ")")) {
				attr(text1, "transform", text1_transform_value);
			}

			if (!/*selectedIsEmpty*/ ctx[11]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(svg, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (!current || dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (!/*selectedIsEmpty*/ ctx[11]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(axis0.$$.fragment, local);
			transition_in(axis1.$$.fragment, local);

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			transition_out(axis0.$$.fragment, local);
			transition_out(axis1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_component(axis0);
			destroy_component(axis1);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
			if (if_block0) if_block0.d();
			if (detaching) detach(t2);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
		}
	};
}

// (90:9) {#each history as d, i}
function create_each_block_1(ctx) {
	let circle;
	let circle_cx_value;
	let circle_cy_value;
	let circle_r_value;
	let circle_intro;

	return {
		c() {
			circle = svg_element("circle");
			attr(circle, "cx", circle_cx_value = /*xScale*/ ctx[5](/*d*/ ctx[27].tomr));
			attr(circle, "cy", circle_cy_value = /*adjustYScale*/ ctx[10](/*d*/ ctx[27][/*selection*/ ctx[1]]));
			attr(circle, "r", circle_r_value = 0);
			attr(circle, "class", "data-point svelte-1bfngop");
		},
		m(target, anchor) {
			insert(target, circle, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*xScale, history*/ 48 && circle_cx_value !== (circle_cx_value = /*xScale*/ ctx[5](/*d*/ ctx[27].tomr))) {
				attr(circle, "cx", circle_cx_value);
			}

			if (dirty & /*adjustYScale, history, selection*/ 1042 && circle_cy_value !== (circle_cy_value = /*adjustYScale*/ ctx[10](/*d*/ ctx[27][/*selection*/ ctx[1]]))) {
				attr(circle, "cy", circle_cy_value);
			}
		},
		i(local) {
			if (!circle_intro) {
				add_render_callback(() => {
					circle_intro = create_in_transition(circle, /*animateCircle*/ ctx[18], { duration: 5000, i: /*i*/ ctx[26] });
					circle_intro.start();
				});
			}
		},
		o: noop,
		d(detaching) {
			if (detaching) detach(circle);
		}
	};
}

// (100:9) {#each events as event, i}
function create_each_block(ctx) {
	let circle;
	let circle_cx_value;
	let circle_cy_value;
	let circle_r_value;
	let circle_intro;

	return {
		c() {
			circle = svg_element("circle");
			attr(circle, "cx", circle_cx_value = /*xScale*/ ctx[5](/*event*/ ctx[24].tomr));
			attr(circle, "cy", circle_cy_value = /*adjustYScale*/ ctx[10](/*event*/ ctx[24][/*selection*/ ctx[1]]));
			attr(circle, "r", circle_r_value = 0);
			attr(circle, "class", "svelte-1bfngop");
			toggle_class(circle, "ipc-point", /*event*/ ctx[24].ipc === 1);
			toggle_class(circle, "pic-point", /*event*/ ctx[24].pic < 7);
		},
		m(target, anchor) {
			insert(target, circle, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*xScale, events*/ 544 && circle_cx_value !== (circle_cx_value = /*xScale*/ ctx[5](/*event*/ ctx[24].tomr))) {
				attr(circle, "cx", circle_cx_value);
			}

			if (dirty & /*adjustYScale, events, selection*/ 1538 && circle_cy_value !== (circle_cy_value = /*adjustYScale*/ ctx[10](/*event*/ ctx[24][/*selection*/ ctx[1]]))) {
				attr(circle, "cy", circle_cy_value);
			}

			if (dirty & /*events*/ 512) {
				toggle_class(circle, "ipc-point", /*event*/ ctx[24].ipc === 1);
			}

			if (dirty & /*events*/ 512) {
				toggle_class(circle, "pic-point", /*event*/ ctx[24].pic < 7);
			}
		},
		i(local) {
			if (!circle_intro) {
				add_render_callback(() => {
					circle_intro = create_in_transition(circle, /*animateCircle*/ ctx[18], { duration: 5000, i: /*i*/ ctx[26] });
					circle_intro.start();
				});
			}
		},
		o: noop,
		d(detaching) {
			if (detaching) detach(circle);
		}
	};
}

// (129:9) {#if !selectedIsEmpty}
function create_if_block_2(ctx) {
	let circle;
	let circle_cx_value;
	let circle_cy_value;

	return {
		c() {
			circle = svg_element("circle");
			attr(circle, "class", "data-point-highlight svelte-1bfngop");
			attr(circle, "cx", circle_cx_value = /*xScale*/ ctx[5](/*history*/ ctx[4][/*$selected*/ ctx[7]].tomr));
			attr(circle, "cy", circle_cy_value = /*adjustYScale*/ ctx[10](/*history*/ ctx[4][/*$selected*/ ctx[7]][/*selection*/ ctx[1]]));
			attr(circle, "r", radius);
		},
		m(target, anchor) {
			insert(target, circle, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*xScale, history, $selected*/ 176 && circle_cx_value !== (circle_cx_value = /*xScale*/ ctx[5](/*history*/ ctx[4][/*$selected*/ ctx[7]].tomr))) {
				attr(circle, "cx", circle_cx_value);
			}

			if (dirty & /*adjustYScale, history, $selected, selection*/ 1170 && circle_cy_value !== (circle_cy_value = /*adjustYScale*/ ctx[10](/*history*/ ctx[4][/*$selected*/ ctx[7]][/*selection*/ ctx[1]]))) {
				attr(circle, "cy", circle_cy_value);
			}
		},
		d(detaching) {
			if (detaching) detach(circle);
		}
	};
}

// (139:6) {#if !selectedIsEmpty}
function create_if_block_1(ctx) {
	let div;
	let t0;
	let t1_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].acid + "";
	let t1;
	let br0;
	let t2;
	let t3_value = (/*history*/ ctx[4][/*$selected*/ ctx[7]].alt?.toFixed(2) ?? "---") + "";
	let t3;
	let t4;
	let br1;
	let t5;
	let t6_value = (/*history*/ ctx[4][/*$selected*/ ctx[7]].groundSpeed?.toFixed(2) ?? "---") + "";
	let t6;
	let t7;
	let br2;
	let t8;
	let t9_value = (/*history*/ ctx[4][/*$selected*/ ctx[7]].verticalRate?.toFixed(2) ?? "---") + "";
	let t9;
	let t10;
	let br3;
	let t11;
	let t12_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].pic + "";
	let t12;
	let br4;
	let t13;
	let t14_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].ipc + "";
	let t14;
	let br5;
	let t15;
	let t16_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].tomr.toISOString() + "";
	let t16;

	return {
		c() {
			div = element("div");
			t0 = text("Aircraft ID: ");
			t1 = text(t1_value);
			br0 = element("br");
			t2 = text("\n            Altitude: ");
			t3 = text(t3_value);
			t4 = text("ft");
			br1 = element("br");
			t5 = text("\n            Speed: ");
			t6 = text(t6_value);
			t7 = text("kt");
			br2 = element("br");
			t8 = text("\n            Vert. Rate: ");
			t9 = text(t9_value);
			t10 = text("ft/min");
			br3 = element("br");
			t11 = text("\n            PIC: ");
			t12 = text(t12_value);
			br4 = element("br");
			t13 = text("\n            IPC: ");
			t14 = text(t14_value);
			br5 = element("br");
			t15 = space();
			t16 = text(t16_value);
			set_style(div, "top", /*adjustYScale*/ ctx[10](/*history*/ ctx[4][/*$selected*/ ctx[7]][/*selection*/ ctx[1]]) + 16 + "px");

			set_style(div, "left", /*$flipTooltip*/ ctx[12]
			? "auto"
			: 16 + /*xScale*/ ctx[5](/*history*/ ctx[4][/*$selected*/ ctx[7]].tomr) + "px");

			set_style(div, "right", /*$flipTooltip*/ ctx[12]
			? /*width*/ ctx[3] + 16 - /*xScale*/ ctx[5](/*history*/ ctx[4][/*$selected*/ ctx[7]].tomr) + "px"
			: "auto");

			attr(div, "class", "tooltip mdc-elevation--z6 svelte-1bfngop");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t0);
			append(div, t1);
			append(div, br0);
			append(div, t2);
			append(div, t3);
			append(div, t4);
			append(div, br1);
			append(div, t5);
			append(div, t6);
			append(div, t7);
			append(div, br2);
			append(div, t8);
			append(div, t9);
			append(div, t10);
			append(div, br3);
			append(div, t11);
			append(div, t12);
			append(div, br4);
			append(div, t13);
			append(div, t14);
			append(div, br5);
			append(div, t15);
			append(div, t16);
			/*div_binding*/ ctx[21](div);
		},
		p(ctx, dirty) {
			if (dirty & /*history, $selected*/ 144 && t1_value !== (t1_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].acid + "")) set_data(t1, t1_value);
			if (dirty & /*history, $selected*/ 144 && t3_value !== (t3_value = (/*history*/ ctx[4][/*$selected*/ ctx[7]].alt?.toFixed(2) ?? "---") + "")) set_data(t3, t3_value);
			if (dirty & /*history, $selected*/ 144 && t6_value !== (t6_value = (/*history*/ ctx[4][/*$selected*/ ctx[7]].groundSpeed?.toFixed(2) ?? "---") + "")) set_data(t6, t6_value);
			if (dirty & /*history, $selected*/ 144 && t9_value !== (t9_value = (/*history*/ ctx[4][/*$selected*/ ctx[7]].verticalRate?.toFixed(2) ?? "---") + "")) set_data(t9, t9_value);
			if (dirty & /*history, $selected*/ 144 && t12_value !== (t12_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].pic + "")) set_data(t12, t12_value);
			if (dirty & /*history, $selected*/ 144 && t14_value !== (t14_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].ipc + "")) set_data(t14, t14_value);
			if (dirty & /*history, $selected*/ 144 && t16_value !== (t16_value = /*history*/ ctx[4][/*$selected*/ ctx[7]].tomr.toISOString() + "")) set_data(t16, t16_value);

			if (dirty & /*adjustYScale, history, $selected, selection*/ 1170) {
				set_style(div, "top", /*adjustYScale*/ ctx[10](/*history*/ ctx[4][/*$selected*/ ctx[7]][/*selection*/ ctx[1]]) + 16 + "px");
			}

			if (dirty & /*$flipTooltip, xScale, history, $selected*/ 4272) {
				set_style(div, "left", /*$flipTooltip*/ ctx[12]
				? "auto"
				: 16 + /*xScale*/ ctx[5](/*history*/ ctx[4][/*$selected*/ ctx[7]].tomr) + "px");
			}

			if (dirty & /*$flipTooltip, width, xScale, history, $selected*/ 4280) {
				set_style(div, "right", /*$flipTooltip*/ ctx[12]
				? /*width*/ ctx[3] + 16 - /*xScale*/ ctx[5](/*history*/ ctx[4][/*$selected*/ ctx[7]].tomr) + "px"
				: "auto");
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			/*div_binding*/ ctx[21](null);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let div_resize_listener;
	let current;
	let mounted;
	let dispose;
	let if_block = /*width*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "chart svelte-1bfngop");
			add_render_callback(() => /*div_elementresize_handler*/ ctx[22].call(div));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			div_resize_listener = add_resize_listener(div, /*div_elementresize_handler*/ ctx[22].bind(div));
			current = true;

			if (!mounted) {
				dispose = [
					listen(div, "mousemove", /*mousemove_handler*/ ctx[23]),
					listen(div, "mouseleave", /*offTooltip*/ ctx[17])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*width*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*width*/ 8) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
			div_resize_listener();
			mounted = false;
			run_all(dispose);
		}
	};
}

let radius = 4;

function instance($$self, $$props, $$invalidate) {
	let history;
	let events;
	let xScale;
	let yScale;
	let adjustYScale;
	let bisect;
	let selectedIsEmpty;
	let path;
	let $flights;
	let $selected;
	let $flipTooltip;
	component_subscribe($$self, flights, $$value => $$invalidate(19, $flights = $$value));
	component_subscribe($$self, selected, $$value => $$invalidate(7, $selected = $$value));
	component_subscribe($$self, flipTooltip, $$value => $$invalidate(12, $flipTooltip = $$value));
	let { textColor = "white" } = $$props;
	let { selection = "" } = $$props;
	let { label = "unknown" } = $$props;
	let tooltip;

	// set the dimensions and margins of the graph
	let margin = { top: 10, right: 30, bottom: 30, left: 60 }, height = 350, width = 0;

	function onTooltip(event) {
		let mx = d3.pointer(event, this)[0];
		const index = bisect(mx);
		set_store_value(flipTooltip, $flipTooltip = event.currentTarget.offsetWidth - event.x < 300, $flipTooltip);
		set_store_value(selected, $selected = index, $selected);
	}

	function offTooltip(event) {
		set_store_value(selected, $selected = -1, $selected);
	}

	function animateCircle(circle, { duration, i }) {
		const dur = duration * (i + 1) / history.length;
		d3.select(circle).attr("r", 0).transition().duration(dur).ease(d3.easeExp).attr("r", radius);
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			tooltip = $$value;
			$$invalidate(8, tooltip);
		});
	}

	function div_elementresize_handler() {
		width = this.clientWidth;
		$$invalidate(3, width);
	}

	const mousemove_handler = event => {
		onTooltip(event);
	};

	$$self.$$set = $$props => {
		if ("textColor" in $$props) $$invalidate(0, textColor = $$props.textColor);
		if ("selection" in $$props) $$invalidate(1, selection = $$props.selection);
		if ("label" in $$props) $$invalidate(2, label = $$props.label);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$flights*/ 524288) {
			$: $$invalidate(4, history = $flights[0]?.history ?? []);
		}

		if ($$self.$$.dirty & /*$flights*/ 524288) {
			$: $$invalidate(9, events = $flights[0]?.events ?? []);
		}

		if ($$self.$$.dirty & /*history, width*/ 24) {
			$: $$invalidate(5, xScale = d3.scaleUtc().domain(d3.extent(history, d => d.tomr)).range([margin.left, width - margin.right]));
		}

		if ($$self.$$.dirty & /*history, selection*/ 18) {
			$: $$invalidate(6, yScale = d3.scaleLinear().domain([
				d3.min(history, d => d[selection]) - 100,
				d3.max(history, d => d[selection])
			]).nice().range([height - margin.bottom, margin.top]));
		}

		if ($$self.$$.dirty & /*yScale*/ 64) {
			$: $$invalidate(10, adjustYScale = p => {
				if (p === null) {
					return 0;
				}

				return yScale(p);
			});
		}

		if ($$self.$$.dirty & /*history, xScale*/ 48) {
			$: $$invalidate(20, bisect = (() => {
				const bisect = d3.bisector(d => d.tomr).left;

				return mx => {
					if (history === null || history === undefined || history.length === 0) {
						return -1;
					}

					const date = xScale.invert(mx);
					const index = bisect(history, date, 1);
					const a = history[index - 1];
					const b = history[index];
					return b && date - a.tomr > b.tomr - date ? index : index - 1;
				};
			})());
		}

		if ($$self.$$.dirty & /*history, $selected*/ 144) {
			$: $$invalidate(11, selectedIsEmpty = history.length > $selected && $selected === -1);
		}

		if ($$self.$$.dirty & /*xScale, yScale, selection*/ 98) {
			$: $$invalidate(13, path = d3.line().x(d => xScale(d.tomr)).y(d => yScale(d[selection])));
		}
	};

	return [
		textColor,
		selection,
		label,
		width,
		history,
		xScale,
		yScale,
		$selected,
		tooltip,
		events,
		adjustYScale,
		selectedIsEmpty,
		$flipTooltip,
		path,
		margin,
		height,
		onTooltip,
		offTooltip,
		animateCircle,
		$flights,
		bisect,
		div_binding,
		div_elementresize_handler,
		mousemove_handler
	];
}

class EventChart extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { textColor: 0, selection: 1, label: 2 });
	}
}

export default EventChart;