import './PolygonEventHistogram.svelte.css';
/* src/PolygonEventHistogram.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	add_render_callback,
	add_resize_listener,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	svg_element,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import * as d3 from "../_snowpack/pkg/d3.js";
import Axis from "./Axis.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	child_ctx[11] = i;
	return child_ctx;
}

// (28:3) {#if width}
function create_if_block(ctx) {
	let svg;
	let axis0;
	let axis1;
	let text0;
	let t0;
	let text0_transform_value;
	let text0_x_value;
	let text0_y_value;
	let text1;
	let t1;
	let text1_transform_value;
	let current;

	axis0 = new Axis({
			props: {
				color: /*textColor*/ ctx[1],
				width: /*width*/ ctx[3],
				height: /*height*/ ctx[7],
				margin: /*margin*/ ctx[6].bottom,
				scale: /*xScale*/ ctx[4],
				position: "bottom"
			}
		});

	axis1 = new Axis({
			props: {
				integersOnly: true,
				color: /*textColor*/ ctx[1],
				width: /*width*/ ctx[3],
				height: /*height*/ ctx[7],
				margin: /*margin*/ ctx[6].left,
				scale: /*yScale*/ ctx[5],
				position: "left"
			}
		});

	let each_value = /*countData*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			create_component(axis0.$$.fragment);
			create_component(axis1.$$.fragment);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			text0 = svg_element("text");
			t0 = text("Aircraft with Low PIC\n         ");
			text1 = svg_element("text");
			t1 = text("Date");
			attr(text0, "class", "chart-label svelte-1c0mpu4");
			attr(text0, "transform", text0_transform_value = "rotate(-90)");
			attr(text0, "x", text0_x_value = -(/*height*/ ctx[7] / 2));
			attr(text0, "y", text0_y_value = 35);
			attr(text0, "text-anchor", "middle");
			attr(text1, "class", "chart-label svelte-1c0mpu4");
			attr(text1, "transform", text1_transform_value = "translate(" + /*width*/ ctx[3] / 2 + " ," + /*height*/ ctx[7] + ")");
			attr(text1, "text-anchor", "middle");
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[7]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			mount_component(axis0, svg, null);
			mount_component(axis1, svg, null);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(svg, null);
			}

			append(svg, text0);
			append(text0, t0);
			append(svg, text1);
			append(text1, t1);
			current = true;
		},
		p(ctx, dirty) {
			const axis0_changes = {};
			if (dirty & /*textColor*/ 2) axis0_changes.color = /*textColor*/ ctx[1];
			if (dirty & /*width*/ 8) axis0_changes.width = /*width*/ ctx[3];
			if (dirty & /*xScale*/ 16) axis0_changes.scale = /*xScale*/ ctx[4];
			axis0.$set(axis0_changes);
			const axis1_changes = {};
			if (dirty & /*textColor*/ 2) axis1_changes.color = /*textColor*/ ctx[1];
			if (dirty & /*width*/ 8) axis1_changes.width = /*width*/ ctx[3];
			if (dirty & /*yScale*/ 32) axis1_changes.scale = /*yScale*/ ctx[5];
			axis1.$set(axis1_changes);

			if (dirty & /*xScale, countData, yScale, barWidth, height, margin, Math*/ 245) {
				each_value = /*countData*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(svg, text0);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (!current || dirty & /*width*/ 8 && text1_transform_value !== (text1_transform_value = "translate(" + /*width*/ ctx[3] / 2 + " ," + /*height*/ ctx[7] + ")")) {
				attr(text1, "transform", text1_transform_value);
			}

			if (!current || dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(axis0.$$.fragment, local);
			transition_in(axis1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(axis0.$$.fragment, local);
			transition_out(axis1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_component(axis0);
			destroy_component(axis1);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (33:9) {#each countData as count, i}
function create_each_block(ctx) {
	let text_1;
	let t_value = /*count*/ ctx[9].events + "";
	let t;
	let text_1_x_value;
	let text_1_y_value;
	let rect;
	let rect_x_value;
	let rect_y_value;
	let rect_height_value;

	return {
		c() {
			text_1 = svg_element("text");
			t = text(t_value);
			rect = svg_element("rect");
			attr(text_1, "x", text_1_x_value = /*xScale*/ ctx[4](/*count*/ ctx[9].tomr) + Math.floor(/*barWidth*/ ctx[2] / 2) - 4);
			attr(text_1, "y", text_1_y_value = /*yScale*/ ctx[5](/*count*/ ctx[9].events) - 5);
			attr(text_1, "class", "svelte-1c0mpu4");
			attr(rect, "class", "data-point svelte-1c0mpu4");
			attr(rect, "x", rect_x_value = /*xScale*/ ctx[4](/*count*/ ctx[9].tomr) + 1);
			attr(rect, "y", rect_y_value = /*yScale*/ ctx[5](/*count*/ ctx[9].events));
			attr(rect, "width", /*barWidth*/ ctx[2]);
			attr(rect, "height", rect_height_value = /*height*/ ctx[7] - /*margin*/ ctx[6].bottom - /*yScale*/ ctx[5](/*count*/ ctx[9].events));
		},
		m(target, anchor) {
			insert(target, text_1, anchor);
			append(text_1, t);
			insert(target, rect, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*countData*/ 1 && t_value !== (t_value = /*count*/ ctx[9].events + "")) set_data(t, t_value);

			if (dirty & /*xScale, countData, barWidth*/ 21 && text_1_x_value !== (text_1_x_value = /*xScale*/ ctx[4](/*count*/ ctx[9].tomr) + Math.floor(/*barWidth*/ ctx[2] / 2) - 4)) {
				attr(text_1, "x", text_1_x_value);
			}

			if (dirty & /*yScale, countData*/ 33 && text_1_y_value !== (text_1_y_value = /*yScale*/ ctx[5](/*count*/ ctx[9].events) - 5)) {
				attr(text_1, "y", text_1_y_value);
			}

			if (dirty & /*xScale, countData*/ 17 && rect_x_value !== (rect_x_value = /*xScale*/ ctx[4](/*count*/ ctx[9].tomr) + 1)) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty & /*yScale, countData*/ 33 && rect_y_value !== (rect_y_value = /*yScale*/ ctx[5](/*count*/ ctx[9].events))) {
				attr(rect, "y", rect_y_value);
			}

			if (dirty & /*barWidth*/ 4) {
				attr(rect, "width", /*barWidth*/ ctx[2]);
			}

			if (dirty & /*yScale, countData*/ 33 && rect_height_value !== (rect_height_value = /*height*/ ctx[7] - /*margin*/ ctx[6].bottom - /*yScale*/ ctx[5](/*count*/ ctx[9].events))) {
				attr(rect, "height", rect_height_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text_1);
			if (detaching) detach(rect);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let div_resize_listener;
	let current;
	let if_block = /*width*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "chart svelte-1c0mpu4");
			add_render_callback(() => /*div_elementresize_handler*/ ctx[8].call(div));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			div_resize_listener = add_resize_listener(div, /*div_elementresize_handler*/ ctx[8].bind(div));
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*width*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*width*/ 8) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
			div_resize_listener();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let xScale;
	let yScale;
	let { countData = [] } = $$props;
	let { textColor = "white" } = $$props;
	let { barWidth = 30 } = $$props;

	// set the dimensions and margins of the graph
	let margin = { top: 10, right: 30, bottom: 50, left: 60 },
		height = 370,
		width = 750;

	function div_elementresize_handler() {
		width = this.clientWidth;
		$$invalidate(3, width);
	}

	$$self.$$set = $$props => {
		if ("countData" in $$props) $$invalidate(0, countData = $$props.countData);
		if ("textColor" in $$props) $$invalidate(1, textColor = $$props.textColor);
		if ("barWidth" in $$props) $$invalidate(2, barWidth = $$props.barWidth);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*countData, width*/ 9) {
			$: $$invalidate(4, xScale = d3.scaleUtc().domain([
				d3.min(countData, d => d.tomr),
				d3.max(countData, d => {
					return new Date(d.tomr).setMinutes(d.tomr.getMinutes() + 1);
				})
			]).range([margin.left, width - margin.right]));
		}

		if ($$self.$$.dirty & /*countData*/ 1) {
			$: $$invalidate(5, yScale = d3.scaleLinear().domain([d3.min(countData, d => d.events), d3.max(countData, d => d.events) + 1]).nice().range([height - margin.bottom, margin.top]));
		}
	};

	return [
		countData,
		textColor,
		barWidth,
		width,
		xScale,
		yScale,
		margin,
		height,
		div_elementresize_handler
	];
}

class PolygonEventHistogram extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { countData: 0, textColor: 1, barWidth: 2 });
	}
}

export default PolygonEventHistogram;