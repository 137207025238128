import './EventDataTable.svelte.css';
/* src/EventDataTable.svelte generated by Svelte v3.37.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_style,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { flights } from "./TargetDataStore.js";
import EventRow from "./EventRow.svelte.js";

function create_if_block_3(ctx) {
	let eventrow;
	let current;

	eventrow = new EventRow({
			props: { data: /*start*/ ctx[0], notes: "Start" }
		});

	return {
		c() {
			create_component(eventrow.$$.fragment);
		},
		m(target, anchor) {
			mount_component(eventrow, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const eventrow_changes = {};
			if (dirty & /*start*/ 1) eventrow_changes.data = /*start*/ ctx[0];
			eventrow.$set(eventrow_changes);
		},
		i(local) {
			if (current) return;
			transition_in(eventrow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(eventrow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(eventrow, detaching);
		}
	};
}

// (49:3) {#if eventStart}
function create_if_block_2(ctx) {
	let eventrow;
	let current;

	eventrow = new EventRow({
			props: {
				data: /*eventStart*/ ctx[2],
				notes: "First Detect"
			}
		});

	return {
		c() {
			create_component(eventrow.$$.fragment);
		},
		m(target, anchor) {
			mount_component(eventrow, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const eventrow_changes = {};
			if (dirty & /*eventStart*/ 4) eventrow_changes.data = /*eventStart*/ ctx[2];
			eventrow.$set(eventrow_changes);
		},
		i(local) {
			if (current) return;
			transition_in(eventrow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(eventrow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(eventrow, detaching);
		}
	};
}

// (52:3) {#if eventEnd}
function create_if_block_1(ctx) {
	let eventrow;
	let current;

	eventrow = new EventRow({
			props: {
				data: /*eventEnd*/ ctx[3],
				notes: "Last Detect"
			}
		});

	return {
		c() {
			create_component(eventrow.$$.fragment);
		},
		m(target, anchor) {
			mount_component(eventrow, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const eventrow_changes = {};
			if (dirty & /*eventEnd*/ 8) eventrow_changes.data = /*eventEnd*/ ctx[3];
			eventrow.$set(eventrow_changes);
		},
		i(local) {
			if (current) return;
			transition_in(eventrow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(eventrow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(eventrow, detaching);
		}
	};
}

// (55:3) {#if end}
function create_if_block(ctx) {
	let eventrow;
	let current;

	eventrow = new EventRow({
			props: { data: /*end*/ ctx[1], notes: "End" }
		});

	return {
		c() {
			create_component(eventrow.$$.fragment);
		},
		m(target, anchor) {
			mount_component(eventrow, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const eventrow_changes = {};
			if (dirty & /*end*/ 2) eventrow_changes.data = /*end*/ ctx[1];
			eventrow.$set(eventrow_changes);
		},
		i(local) {
			if (current) return;
			transition_in(eventrow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(eventrow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(eventrow, detaching);
		}
	};
}

function create_fragment(ctx) {
	let table;
	let tr;
	let t15;
	let t16;
	let t17;
	let t18;
	let current;
	let if_block0 = /*start*/ ctx[0] && create_if_block_3(ctx);
	let if_block1 = /*eventStart*/ ctx[2] && create_if_block_2(ctx);
	let if_block2 = /*eventEnd*/ ctx[3] && create_if_block_1(ctx);
	let if_block3 = /*end*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			table = element("table");
			tr = element("tr");

			tr.innerHTML = `<th class="svelte-1qyz593">Datetime (UTC)</th> 
      <th class="svelte-1qyz593">Latitude</th> 
      <th class="svelte-1qyz593">Longitude</th> 
      <th class="alt-cell svelte-1qyz593">Alt (ft)</th> 
      <th class="svelte-1qyz593">PIC</th> 
      <th class="svelte-1qyz593">IPC</th> 
      <th class="speed-cell svelte-1qyz593">Ground Speed (kt)</th> 
      <th class="svelte-1qyz593">Notes</th>`;

			t15 = space();
			if (if_block0) if_block0.c();
			t16 = space();
			if (if_block1) if_block1.c();
			t17 = space();
			if (if_block2) if_block2.c();
			t18 = space();
			if (if_block3) if_block3.c();
			set_style(tr, "text-align", "left");
			attr(table, "class", "svelte-1qyz593");
		},
		m(target, anchor) {
			insert(target, table, anchor);
			append(table, tr);
			append(table, t15);
			if (if_block0) if_block0.m(table, null);
			append(table, t16);
			if (if_block1) if_block1.m(table, null);
			append(table, t17);
			if (if_block2) if_block2.m(table, null);
			append(table, t18);
			if (if_block3) if_block3.m(table, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*start*/ ctx[0]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*start*/ 1) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(table, t16);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*eventStart*/ ctx[2]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*eventStart*/ 4) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(table, t17);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*eventEnd*/ ctx[3]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*eventEnd*/ 8) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(table, t18);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*end*/ ctx[1]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);

					if (dirty & /*end*/ 2) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(table, null);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(if_block3);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(if_block3);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(table);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let history;
	let events;
	let $flights;
	component_subscribe($$self, flights, $$value => $$invalidate(5, $flights = $$value));
	let start = undefined;
	let end = undefined;
	let eventStart = undefined;
	let eventEnd = undefined;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$flights*/ 32) {
			$: $$invalidate(4, history = $flights[0]?.history ?? []);
		}

		if ($$self.$$.dirty & /*$flights*/ 32) {
			$: $$invalidate(6, events = $flights[0]?.events ?? []);
		}

		if ($$self.$$.dirty & /*history*/ 16) {
			$: if (history.length > 0) {
				$$invalidate(0, start = history[0]);
				$$invalidate(1, end = history[history.length - 1]);
			} else {
				$$invalidate(0, start = undefined);
				$$invalidate(1, end = undefined);
			}
		}

		if ($$self.$$.dirty & /*events*/ 64) {
			$: if (events.length > 0) {
				$$invalidate(2, eventStart = events[0]);
			} else {
				$$invalidate(2, eventStart = undefined);
			}
		}

		if ($$self.$$.dirty & /*events*/ 64) {
			$: if (events.length > 1) {
				$$invalidate(3, eventEnd = events[events.length - 1]);
			} else {
				$$invalidate(3, eventEnd = undefined);
			}
		}
	};

	return [start, end, eventStart, eventEnd, history, $flights, events];
}

class EventDataTable extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default EventDataTable;